/* eslint-disable no-lone-blocks */
/**
 * IMPORTS
 */

import { getFunc } from "Services/mainApiServices";
import { getChatFunc, postChatFunc } from "Services/rocketApiServices";

/**
 * TYPES
 */

const GET_CHAT_ROOMS = "chat/GET_CHAT_ROOMS";
const UPDATE_CHAT_ROOMS = "chat/UPDATE_CHAT_ROOMS";
const GET_CHAT_USERS = "chat/GET_CHAT_USERS";
const CREATE_CHAT_ROOM = "chat/CREATE_CHAT_ROOM";
const DELETE_CHAT_ROOM = "chat/DELETE_CHAT_ROOM";
const LOAD_HISTORY = "chat/LOAD_HISTORY";
const SEND_MESSAGE = "chat/SEND_MESSAGE";
const APPEND_MESSAGE = "chat/APPEND_MESSAGE";
const READ_MESSAGE = "chat/READ_MESSAGE";
const CLEAR_MESSAGES = "chat/CLEAR_MESSAGES";

/**
 * ACTIONS
 */

export const getChatRooms = (realTimeAPI, callback) => async dispatch => {
  const response = await getChatFunc("api/v1/subscriptions.get");

  if (response?.success) {
    callback(response?.update);
    dispatch({ type: GET_CHAT_ROOMS, payload: response });
  } else if (!response) {
    localStorage.clear();
    realTimeAPI.disconnect();
    window.location.reload();
  }
};

export const updateChatRooms = data => async dispatch => {
  dispatch({ type: UPDATE_CHAT_ROOMS, payload: data });
};

export const getChatUsers = (ten, org) => async dispatch => {
  const response = await getChatFunc(
    `api/v1/users.list?query={"active": true, "customFields.admin":{"$eq":1}, "customFields.tenant":{"$eq":"${ten}"}, "customFields.org":{"$eq":"${org}"}}`
  );

  if (response?.success) {
    dispatch({
      type: GET_CHAT_USERS,
      payload: response
    });
  }
};

export const createChatRoom = (body, callback) => async dispatch => {
  const response = await postChatFunc(
    `api/v1/method.call/createDirectMessage`,
    body
  );

  if (response?.success) {
    callback();
    dispatch({
      type: CREATE_CHAT_ROOM,
      payload: response
    });
  }
};

export const deleteChatRoom = (body, room, callback) => async dispatch => {
  const response = await postChatFunc(
    `api/v1/method.call/createDirectMessage`,
    body
  );

  if (response?.success) {
    callback();
    dispatch({
      type: DELETE_CHAT_ROOM,
      payload: room
    });
  }
};

export const getChatHistory = body => async dispatch => {
  const response = await postChatFunc(`api/v1/method.call/loadHistory`, body);

  if (response?.success) {
    dispatch({
      type: LOAD_HISTORY,
      payload: response
    });
  }
};

export const sendMessage = (body, callback) => async dispatch => {
  const response = await postChatFunc(`api/v1/method.call/sendMessage`, body);

  if (response?.success) {
    callback();
    dispatch({
      type: SEND_MESSAGE,
      payload: response
    });
  }
};

export const appendMessage = data => async dispatch => {
  dispatch({
    type: APPEND_MESSAGE,
    payload: data
  });
};

export const readMessages = body => async dispatch => {
  const response = await postChatFunc(`api/v1/method.call/readMessages`, body);

  if (response?.success) {
    dispatch({
      type: READ_MESSAGE,
      payload: response
    });
  }
};

export const clearMessages = () => async dispatch => {
  dispatch({
    type: CLEAR_MESSAGES
  });
};

/**
 * REDUCERS
 */

const INIT_STATE = {
  rooms: [],
  users: [],
  messages: []
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_CHAT_ROOMS:
      return {
        ...state,
        rooms: action.payload.update.filter(room => room.rid !== "GENERAL")
      };
    case UPDATE_CHAT_ROOMS:
      if (action.payload[0] === "updated") {
        {
          return {
            ...state,
            rooms: state.rooms.map(room => {
              if (room._id === action.payload[1]._id) {
                return action.payload[1];
              }
              return room;
            })
          };
        }
      } else if (action.payload[0] === "removed") {
        return {
          ...state,
          rooms: state.rooms.filter(room => room._id !== action.payload[1]._id)
        };
      } else if (action.payload[0] === "inserted") {
        return {
          ...state,
          rooms: [...state.rooms, action.payload[1]]
        };
      } else {
        return { ...state };
      }
    case GET_CHAT_USERS:
      return {
        ...state,
        users: action.payload.users
      };
    case CREATE_CHAT_ROOM:
      return {
        ...state
      };
    case DELETE_CHAT_ROOM:
      return {
        ...state
      };
    case LOAD_HISTORY:
      return {
        ...state,
        messages: JSON.parse(action.payload.message)?.result?.messages.sort(
          (a, b) => a.ts.$date - b.ts.$date
        )
      };
    case APPEND_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case READ_MESSAGE:
      return {
        ...state
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: []
      };
    default:
      return state;
  }
}
