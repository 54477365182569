/* eslint-disable import/no-unresolved */
// React
import { lazy } from "react";

// Routes (Non splitted)
import BaseLayout from "Layouts/BaseLayout";
//import TripleLayout from "Layouts/TripleLayout";
//import TripleCTALayout from "Layouts/TripleCTALayout";
import ChatLayout from "Layouts/ChatLayout";
import TripleAlternativeLayout from "Layouts/alternative/TripleAlternativeLayout";
import TripleSearchCTALayout from "Layouts/alternative/TripleSearchCTALayout";
import TripleSearchCollapseLayout from "Layouts/alternative/TripleSearchCollapseLayout";

// Routes (Code splitting)
const Login = lazy(() => import("Pages/login/index.js"));
const AutoLogin = lazy(() => import("Pages/login/auto-login.js"));
const Home = lazy(() => import("Pages/home/index.js"));
const Browse = lazy(() => import("Pages/browse/index.js"));
const SelectView = lazy(() => import("Pages/browse/SelectView.js"));
const SelectViewCollapse = lazy(() =>
	import("Pages/browse/SelectViewCollapseWrapper.js")
);
const ViewAnItem = lazy(() => import("Pages/browse/ViewAnItem.js"));
const MyProfile = lazy(() => import("Pages/profile/index.js"));
const RateStay = lazy(() => import("Pages/profile/RateStay.js"));
const Chat = lazy(() => import("Pages/chat/index.js"));
const Order = lazy(() => import("Pages/order/index.js"));
const Welcome = lazy(() => import("Pages/welcome/index.js"));
const Tutorial = lazy(() => import("Pages/tutorial/index.js"));

/**
|--------------------------------------------------
| PUBLIC ROUTES
|--------------------------------------------------
*/

export const publicRoutes = [
	{
		id: "login",
		title: "Login",
		description: "Enter the key and log in to triplehost",
		path: "",
		exact: true,
		component: Login,
		layout: BaseLayout,
	},
	{
		id: "autoLogin",
		title: "Auto login",
		description: "Login with QR code",
		path: "auto-login/:key",
		exact: true,
		component: AutoLogin,
		layout: BaseLayout,
	},
];

/**
|--------------------------------------------------
| PRIVATE ROUTES
|--------------------------------------------------
*/

export const privateRoutes = [
	{
		id: "home",
		title: "Homepage",
		description: "Guest app homepage",
		path: "home",
		component: Home,
		layout: TripleAlternativeLayout,
	},
	{
		id: "browse",
		title: "Browse",
		description: "Browse items and services",
		path: "browse",
		component: Browse,
		layout: TripleAlternativeLayout,
	},
	{
		id: "selectView",
		title: "List of items",
		description: "See list of items from one category",
		path: "selectview/:id",
		component: SelectView,
		layout: TripleAlternativeLayout,
	},
	{
		id: "selectViewCollapse",
		title: "List of items",
		description: "See list of items from one category",
		path: "selectviewcollapse/:id",
		component: SelectViewCollapse,
		layout: TripleSearchCollapseLayout,
	},
	{
		id: "viewAnItem",
		title: "View an item",
		description: "View an item from select list",
		path: "singleview/:id/:camp_id",
		component: ViewAnItem,
		layout: TripleSearchCTALayout,
	},
	{
		id: "profile",
		title: "My Profile",
		description: "Profile actions",
		path: "profile",
		component: MyProfile,
		layout: TripleAlternativeLayout,
	},
	{
		id: "rateStay",
		title: "Rate Your Stay",
		description: "Rate Your Stay",
		path: "rating/:id",
		component: RateStay,
		layout: TripleAlternativeLayout,
	},
	{
		id: "chat",
		title: "Chat With Us",
		description: "For communication",
		path: "chat",
		component: Chat,
		layout: ChatLayout,
	},
	{
		id: "order",
		title: "Ordering",
		description: "Food & Activities",
		path: "orders",
		component: Order,
		layout: TripleAlternativeLayout,
	},
	{
		id: "welcome",
		title: "Welcome!",
		description: "Welcome message",
		path: "welcome",
		component: Welcome,
		layout: BaseLayout,
	},
	{
		id: "tutorial",
		title: "Tutorial",
		description: "Tutorial",
		path: "tutorial/:goto",
		component: Tutorial,
		layout: BaseLayout,
	},
];
