module.exports = {
	// GLOBAL
	"GLOBAL.NOT_FOUND": "Stranica nije pronađena",
	"GLOBAL.INVALID_CODE_LABEL": "Nevaljan kod!",
	"GLOBAL.FIRST_NAME_LABEL": "Ime",
	"GLOBAL.LAST_NAME_LABEL": "Prezime",
	"GLOBAL.ROOM_NUMBER_LABEL": "Broj sobe",
	"GLOBAL.CLOSE_ACTION": "zatvori",
	"GLOBAL.CONTINUE_ACTION": "nastavi",
	"GLOBAL.CONFIRM_ACTION": "potvrdi",
	"GLOBAL.PROCEED_ACTION": "nastavi",
	"GLOBAL.SUBMIT_ACTION": "potvrdi",
	"GLOBAL.NEXT_ACTION": "Dalje",
	"GLOBAL.BACK_ACTION": "Natrag",
	"GLOBAL.SKIP_ACTION": "preskoči upute",
	"GLOBAL.SEARCH": "Pretraži...",
	"GLOBAL.TOTAL_LABEL": "ukupno",
	"GLOBAL.PAGE_HOME": "početna",
	"GLOBAL.PAGE_BROWSE": "traži",
	"GLOBAL.PAGE_ORDERS": "narudžbe",
	"GLOBAL.PAGE_CHAT": "chat",
	"GLOBAL.PAGE_PROFILE": "profil",
	"GLOBAL.CHOOSE_LANG_TITLE": "Dobrodošli u",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_1":
		"Za najbolje iskustvo odaberite željeni jezik",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_2": "Uvijek ga možete promijeniti kasnije",
	"GLOBAL.SWW": "Došlo je do pogreške!",
	"GLOBAL.SWW_OPTION_1": "Osvježi",
	"GLOBAL.SWW_OPTION_2": "Idi na početnu stranicu",
	"GLOBAL.LANGUAGE": "Jezik",
	"GLOBAL.SERVICES": "Usluge",
	"GLOBAL.ORDERS": "narudžbe",
	"GLOBAL.APPOINTMENTS": "rezervacije",
	"GLOBAL.EVENTS": "događaji",
	"GLOBAL.OTHERS": "ostalo",
	"GLOBAL.QUANTITY": "količina",
	"GLOBAL.SERVICE": "usluga",
	"GLOBAL.PROCESSING_PAYMENT": "Obrada plaćanja...",

	// STATUSES
	"GLOBAL.STATUS_PROCESSING_LABEL": "Obrada",
	"GLOBAL.STATUS_UNSUCESSFULLY_PROCESSED_LABEL": "Neuspješno obrađeno",
	"GLOBAL.STATUS_FINISHED_LABEL": "Završeno",
	"GLOBAL.STATUS_PENDING_LABEL": "Na čekanju",
	"GLOBAL.STATUS_CANCELED_LABEL": "Otkazano",
	"GLOBAL.STATUS_IN_PROGRESS_LABEL": "U tijeku",

	// SEARCH
	"SEARCH.INPUT_LABEL": "Pretraži",
	"SEARCH.NO_RESULTS": "Nema rezultata pretraživanja",
	"SEARCH.LOADING": "Učitavanje rezultata",
	"SEARCH.MORE_CHARS_LABEL": "Unesite još {{chars}} znak/ova",

	// NOTIFICATIONS
	"NOTIFICATIONS.TITLE": "obavijesti",
	"NOTIFICATIONS.READ_ALL": "označi kao pročitano",

	// PROFILE
	"PROFILE.LOG_OUT": "Odjavi se",
	"PROFILE.NO_ROOM_LABEL": "Gost nije dodjeljen u sobu",
	"PROFILE.CHECKOUT_LABEL": "Odjava",
	"PROFILE.SUBSCRIPTIONS": "Moje pretplate",
	"PROFILE.SUBSCRIPTIONS_USER_INFO_LABEL": "Ime i prezime (broj sobe):",
	"PROFILE.SUBSCRIPTIONS_DURATION_LABEL": "Vrijedi:",
	"PROFILE.NO_SUBSCRIPTIONS":
		"Nemate nijednu pretplatu! Molimo vas, javite se na recepciju kako biste ju dobili!",
	"PROFILE.BALANCE": "Moj račun",
	"PROFILE.MY_SPENDINGS": "Moja potrošnja",
	"PROFILE.SPENDINGS": "Potrošnja",
	"PROFILE.SPENT": "Potrošeno",
	"PROFILE.MY_STAY": "Moj boravak",
	"PROFILE.RATE_STAY": "Ocjeni svoj boravak",
	"PROFILE.BAN": "zabrana",
	"PROFILE.LIMITATION": "ograničenje",
	"PROFILE.SET_LIMIT": "Postavi ograničenje",
	"PROFILE.ENTER_AMOUNT": "Unesi iznos",
	"PROFILE.HOW_TO_USE_APP": "Kako koristiti aplikaciju?",
	"PROFILE.TOTAL_ORDERS": "NARUDŽBE",
	"PROFILE.ROOM_NUMBER": "Broj sobe",
	"PROFILE.NO_RESTRICTIONS": "Bez ograničenja",
	"PROFILE.SET": "Postavi",
	"PROFILE.RATE_ACCOMODATION": "smještaj",
	"PROFILE.RATE_SERVICE": "usluga",
	"PROFILE.RATE_REVIEW_TEXT": "Recenzija",
	"PROFILE.RATE_TEXT_PLACEHOLDER": "Piši ovdje...",
	"PROFILE.RATE_DELETE": "izbriši ocjene",

	// ORDERS
	"ORDERS.TITLE": "moje narudžbe & termini",
	"ORDERS.EMPTY_TXT":
		"Nemate nijednu narudžbu! Napravite ju da bi se prikazala ovdje!",
	"ORDERS.CANCEL_ACTION": "otkaži",
	"ORDERS.ITEM_SHOW_DETAILS": "Pokaži detalje",
	"ORDERS.ITEM_FREE_LABEL": "Besplatno",
	"ORDERS.ITEM_RESERVED_LABEL": "REZERVIRANO",
	"ORDERS.RATE_ACTION": "ocjeni",
	"ORDERS.RATE_SERVICE_LABEL": "usluga",
	"ORDERS.RATE_REVIEW_LABEL": "Recenzija",
	"ORDERS.RATE_MSG_PLACEHOLDER": "Piši ovdje...",
	"ORDERS.CANCEL_LABEL": "otkaži narudžbu",
	"ORDERS.CANCEL_TEXT": `Jeste li sigurni da želite ukloniti {{orderItemQuantity}} X {{orderItemName}} iz narudžbe?`,
	"ORDERS.CANCEL_CONFIRM": "da, siguran/a sam",
	"ORDERS.ON_ROOM_BILL_LABEL": "na račun sobe",
	"ORDERS.TIME_LEFT_FOR_DELIVERY": "{{time}} ostale do spremanja narudžbe",
	"ORDERS.ITEM_PICK_UP_AT_LABEL": "Pokupi u",

	// CHAT
	"CHAT.DISABLED": "Chat je onemogućen",
	"CHAT.NEW_CONVERSATION": "Dodaj novi razgovor",
	"CHAT.REMOVE_CONVERSATION": "Izbriši razgovor",
	"CHAT.TYPE_MESSAGE": "Upiši poruku ovdje",

	// BROWSE
	"BROWSE.TEMPLATE_ORDER_ORDER_LABEL": "odaberi količinu i ostalo",
	"BROWSE.TEMPLATE_EVENT_ORDER_LABEL": "odaberite broj ulaznica",
	"BROWSE.TEMPLATE_DATE_RANGE_ORDER_LABEL": "rezerviraj",
	"BROWSE.TEMPLATE_APPOINTMENT_ORDER_LABEL": "započni proces rezerviranja",
	"BROWSE.REVIEWS_LABEL": "recenzije",
	"BROWSE.TEMPLATE_DATE_RANGE_SUBMIT_ALREADY_IN_CART_MSG":
		"Ovaj artikl je već u košarici!",
	"BROWSE.TEMPLATE_APPOINTMENT_PICK_MSG": "Molimo odaberite termin!",
	"BROWSE.ITEM_NOT_RATED": "nije ocijenjeno",
	"BROWSE.ITEM_MAX_NUM_OF_RESERVATIONS": "Maks. broj rezerviranja odjednom je ",
	"BROWSE.ADDED_TO_CART_MSG": "Artikl je uspješno dodan u košaricu!",
	"BROWSE.ITEM_NO_PRICE_OR_READONLY_OR_DISABLED_ORDERING_MSG":
		"Trenutno, kreiranje rezervacije nije dostupno. Molimo, pokušajte ponovno kasnije!",

	// CART
	"CART.TITLE": "moja košarica",
	"CART.EMPTY_TITLE": "Vaša košarica je prazna!",
	"CART.EMPTY_SUBTITLE":
		"Pretražite ponudu kako biste dodali stavke u košaricu",
	"CART.SCHEDULE_DELIVERY": "zakaži isporuku",
	"CART.SCHEDULED_TIME_GREATER_THAN_CURRENT_TIME_MSG":
		"Planirano vrijeme mora biti veće od trenutnog vremena.",
	"CART.ORGANISATION_WORKING_TIME_FROM_MSG":
		"Radno vrijeme ove organizacije je od",
	"CART.ORGANISATION_WORKING_TIME_TO_MSG": "do",
	"CART.SCHEDULED_TIME_HAS_TO_BE_AFTER_MSG": "Vrijeme dostave mora biti iza ",
	"CART.CHECKOUT_ACTION": "račun",
	"CART.REMOVE_ALL_ACTION": "izbriši sve",
	"CART.TOTAL_LABEL": "ukupno stavki",
	"CART.ITEM_SHOW_DETAILS": "Pokaži detalje",
	"CART.ITEM_FREE_LABEL": "Besplatno",
	"CART.ITEM_ROOM_SERVICE_LABEL": "Posluga u sobu",
	"CART.ITEM_PICK_UP_AT_LABEL": "Pokupi u",
	"CART.ROOM_BILL_ACTION": "stavi na račun sobe",
	"CART.PAY_WITH_CARD_ACTION": "plati karticom",
	"CART.ORDER_SCS_TITLE": "Narudžba uspješno izvršena!",
	"CART.ORDER_SCS_SUBTITLE":
		"Uvijek možete pratiti svoje naružbe u odjeljku NARUŽBE u glavnom izborniku.",
	"CART.ORDER_MORE_ACTION": "naruči više",
	"CART.ORDER_MY_ORDERS_ACTION": "moje narudžbe",
	"CART.ORDER_EXCEEDED_LIMIT_MSG": "Prekoračili ste ograničenje!",
	"CART.ORDER_CANCEL_OR_CONTINUE": "Želite li odustati ili nastaviti?",
	"CART.ORDER_DATE_TO_FROM_VALIDATION_MSG":
		"Krajnji datum mora biti veći od početnog",
	"CART.ORDER_INVALID_ITEMS_MSG": "Neispravne stavke u košarici",
	"CART.ORDER_CARD_PAYMENT_MSG": "Plaćanje karticom za sad nije implementirano",
	//"CART.ORDER_SCHEDULED_TIME_PAST_MSG":"Scheduled date is in past",

	// RATE DIALOG
	"RATE_DIALOG.TITLE": "Ocijeni našu aplikaciju",
	"RATE_DIALOG.RATE_TEXT":
		"Vaša ocjena će nam pomoći da poboljšamo GuestApp. Molimo vas, napišite nam svoje mišljenje!",
	"RATE_DIALOG.RATE_ACTION": "ocijeni",
	"RATE_DIALOG.CANCEL_ACTION": "ne sada",
	"RATE_DIALOG.NO_STARS_MSG": "Molimo odaberite broj zvjezdica od 1 do 5!",

	// ORDER
	"ORDER.CHOOSE_QUANTITY": "Odaberi količinu",
	"ORDER.ADD_TO_CART_ACTION": "dodaj u košaricu",
	"ORDER.TEMPLATE_ORDER_EXTRAS": "Prilog",
	"ORDER.TEMPLATE_ORDER_DELIVERY_TYPE": "Način dostave",
	"ORDER.TEMPLATE_ORDER_ITEM_ROOM_SERVICE_LABEL": "Posluga u sobu",
	"ORDER.TEMPLATE_ORDER_ITEM_PICK_UP_AT_LABEL": "Pokupi u",
	"ORDER.TEMPLATE_DATE_RANGE_STEP_1_TITLE": "Odaberite jedan ili više dana",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_1": "Odaberi datum i količinu",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_2": "Odaberi datum",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_2_TITLE": "Odaberi točan termin",
	"ORDER.TEMPLATE_APPOINTMENT_NO_MAX_CAPACITY_TXT":
		"Trenutno, kreiranje rezervacije nije dostupno. Molimo, pokušajte ponovno kasnije!",
	"ORDER.TEMPLATE_DATE_RANGE_FROM_LABEL": "Od",
	"ORDER.TEMPLATE_DATE_RANGE_TO_LABEL": "Do",

	// TUTORIAL
	"TUTORIAL.SKIP": "preskoči uputstva",

	// VALIDATION
	"VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	//"VALIDATION.STAR_RATING_REQUIRED": "Star rating is required",
	"VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",
	"VALIDATION.STAY_REVIEW_DELETED_SCS":
		"Your review for stay is successfully deleted!",
	"VALIDATION.LIMIT_UPDATED_SCS": "Limit is successfully updated!",
	"VALIDATION.ORDER_ITEM_CANCEL_FLR": "You cannot cancel an order item",
	"VALIDATION.ORDER_ITEM_CANCEL_SCS": "Item is successfully canceled!",
	"VALIDATION.CALENDAR_AVAILABLE_TERMINS_FLR":
		"The calendar has no dates available",
	// "VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	// "VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",

	// OTHERS
	Popular: "Popularno",
	"special offer": "posebna ponuda",
	"Special offer": "Posebna ponuda",
	Ban: "zabrana",
	Limitation: "ograničenje",
};
