// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { CardMedia, Typography, Box } from "@material-ui/core";

// Atoms
import Price from "Components/atoms/UI/Price";
import ReviewStar from "Components/atoms/UI/ReviewStar";
import StatusOfItem from "Components/atoms/UI/StatusOfItem";

const SelectViewContent = (props) => {
  const { t } = useTranslation();

  const {
    image,
    component,
    title,
    review,
    price,
    status,
    currency,
    classes,
  } = props;

  return (
    <>
      {/* <CardMedia
        className={classes.cover}
        image={image}
        component={component}
        title={t(title)}
      /> */}
      <Box
        className={classes.itemImage}
        style={{ backgroundImage: `url(${image})`, width: "53%" }}
      >
        {status ? (
          <StatusOfItem status={status} customSelectViewStyle={true} />
        ) : null}
      </Box>
      <div className={classes.details}>
        <div className={classes.wrapper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography className={classes.header}>{t(title)}</Typography>
            </div>
            <div>{review ? <ReviewStar label={review} /> : null}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {price > 0 && (
              <Price
                label={
                  (price ? price.toFixed(2) : "") +
                  " " +
                  (currency ? currency : "")
                }
              />
            )}

            {/* {status ? <StatusOfItem status={status} /> : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

SelectViewContent.defaultProps = {
  image: "",
  component: "img",
  title: "Title",
  review: "",
  price: "",
  status: false,
  currency: null,
  classes: {},
};

export default SelectViewContent;
