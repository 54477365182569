// React
import React, { useEffect, Suspense, lazy, createContext } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { createID } from "Util/functions";

// Router
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import { publicRoutes, privateRoutes } from "Router/Routes";

// Loaders
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

// // Utils
// import registerSW from "Util/registerSW";

// Misc
const NotFoundPage = lazy(() => import("Pages/not-found/index.js"));

// Chat Socket Connection
import { RealTimeAPI } from "rocket.chat.realtime.api.rxjs";

export const ChatContext = createContext();

const user = JSON.parse(localStorage.getItem("user"));
const chatToken = JSON.parse(localStorage.getItem("data"))?.chat_auth_token;
const loggedChatUser = JSON.parse(localStorage.getItem("data"))?.chat_user_id;

const realTimeAPI = new RealTimeAPI(process.env.CHAT_SOCKET);
/* realTimeAPI.keepAlive().subscribe();
realTimeAPI.sendMessage({
  msg: "connect",
  version: "1",
  support: ["1", "pre2", "pre1"]
});
if (user) {
  realTimeAPI.loginWithAuthToken(chatToken);
  realTimeAPI.sendMessage({
    msg: "sub",
    id: loggedChatUser,
    name: "stream-notify-user",
    params: [`${loggedChatUser}/notification`, false]
  });
} */

const PublicRoutes = ({
	component: CustomComp,
	layout: Layout,
	title,
	description,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) => (
			<Layout>
				<Helmet>
					<title>TripleHost Guest | {title}</title>
					<meta name="cover" content={description} viewport-fit="cover" />
				</Helmet>
				<CustomComp {...props} />
			</Layout>
		)}
	/>
);

const PrivateRoutes = ({
	component: CustomComp,
	layout: Layout,
	user,
	title,
	description,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			user ? (
				<Layout>
					<Helmet>
						<title>TripleHost Guest | {title}</title>
						<meta name="cover" content={description} viewport-fit="cover" />
					</Helmet>
					<CustomComp {...props} />
				</Layout>
			) : (
				<Redirect
					to={{
						pathname: "",
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);

const AppRouter = () => {
	const user = useSelector((state) => state.auth.user);
	const history = useHistory();
	const app = useSelector((state) => state.app);

	const welcome_page = useSelector(
		(state) => state.auth?.data?.organisation?.welcome_page
	);
	const tutorial = useSelector((state) => state.app?.tutorials);
	//const tutorial = JSON.parse(localStorage.getItem("tutorials"));

	const loggedChatUser = useSelector((state) => state.auth.data?.chat_user_id);
	const chatToken = useSelector((state) => state.auth.data?.chat_auth_token);

	useEffect(() => {
		if (realTimeAPI?.webSocket?.closed === true) {
			if (
				window.location.pathname !== "/" &&
				window.location.pathname !== "/welcome" &&
				window.location.pathname !== "/tutorial"
			)
				window.location.reload();
		}
		if (user && realTimeAPI?.webSocket?.closed === false) {
			realTimeAPI.keepAlive().subscribe();
			realTimeAPI.sendMessage({
				msg: "connect",
				version: "1",
				support: ["1", "pre2", "pre1"],
			});
			realTimeAPI.loginWithAuthToken(chatToken);
			realTimeAPI.sendMessage({
				msg: "sub",
				id: loggedChatUser,
				name: "stream-notify-user",
				params: [`${loggedChatUser}/notification`, false],
			});
		}
	}, [user]);

	useEffect(() => {
		if (user !== null && history.location.pathname === "/login") {
			history.push("/");
		}
		if (user && history.location.pathname === "/") {
			history.push("/home");
		}
	}, [user]);

	useEffect(() => {
		if (welcome_page === false && history.location.pathname === "/welcome") {
			history.push("/home");
		}

		return () => {};
	}, [welcome_page]);

	useEffect(() => {
		if (
			tutorial?.length === 0 &&
			(history.location.pathname === "/tutorial/home" ||
				history.location.pathname === "/tutorial/profile")
		) {
			history.push("/home");
		}

		return () => {};
	}, [tutorial]);

	return (
		<>
			<Helmet>
				<link
					rel="manifest"
					href={`${process.env.MAIN_API_URL}/static/Manifest/manifest-${
						window.location.hostname.split(".")[0]
					}.json`}
				/>
				<link rel="icon" type="image/png" href={app.theme?.favicon} />
				<link rel="apple-touch-icon" href={app.theme?.icon} />
			</Helmet>
			<Suspense fallback={<FullScreenSpinner />}>
				<ChatContext.Provider value={realTimeAPI}>
					<Switch>
						{privateRoutes &&
							privateRoutes.map((route) => {
								return (
									<PrivateRoutes
										key={route.id}
										path={`/${route.path}`}
										exact={route.exact}
										component={route.component}
										layout={route.layout}
										title={route.title}
										description={route.description}
										user={user}
									/>
								);
							})}
						{publicRoutes &&
							publicRoutes.map((route) => {
								return (
									<PublicRoutes
										key={route.id}
										path={`/${route.path}`}
										exact={route.exact}
										component={route.component}
										layout={route.layout}
										title={route.title}
										description={route.description}
									/>
								);
							})}
						<Route component={NotFoundPage} />
					</Switch>
				</ChatContext.Provider>
			</Suspense>
		</>
	);
};

export default AppRouter;
