// React
import React from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

// MUI
import { Typography, Divider, Box } from "@material-ui/core";

// Atoms
import Price from "Components/atoms/UI/Price";
import StatusOfItem from "Components/atoms/UI/StatusOfItem";

const ViewAnItemContent = (props) => {
	const { t } = useTranslation();

	const { title, status, descriptionText, currency, classes, price } = props;

	function transform(node, index) {
		if (node.type === "tag" && node.name === "img") {
			return (
				<img
					src={node.attribs.src}
					style={{ maxWidth: "100%", maxHeight: "100vh", margin: "auto" }}
				/>
			);
		}
	}

	const options = {
		decodeEntities: true,
		transform,
	};

	return (
		<>
			<div className={classes.details}>
				<div className={classes.wrapper}>
					<>
						<Typography className={classes.header}>{t(title)}</Typography>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							{price > 0 && (
								<Price
									label={
										(price ? price.toFixed(2) : "") +
										" " +
										(currency ? currency : "")
									}
								/>
							)}

							{status ? <StatusOfItem status={status} /> : null}
						</div>
					</>
					{descriptionText && (
						<Box my={1} style={{ marginBottom: "0px" }}>
							<Divider />
							<Box className={classes.descriptionViewAnItem}>
								<Typography>
									{ReactHtmlParser(descriptionText, options)}
								</Typography>
							</Box>
						</Box>
					)}
				</div>
			</div>
		</>
	);
};

ViewAnItemContent.defaultProps = {
	title: "Title",
	price: "",
	status: false,
	descriptionText: "",
	currency: null,
	classes: {},
};

export default ViewAnItemContent;
