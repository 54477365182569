// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Brown Regular Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Brown Regular Regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\n\tfont-family: \"Brown Regular\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n}\n\n.ios-install-notification {\n\tbackground-color: #272727e0;\n\tcolor: #fff;\n\tposition: fixed;\n\tbottom: 0;\n\tright: 0;\n\tpadding: 1.7em 1.9em;\n\tborder-radius: 50px;\n\ttext-decoration: none;\n\twidth: 100%;\n\tanimation: wiggle 2.5s infinite;\n\tmargin-bottom: 5px;\n\ttext-align: center;\n\tz-index: 999999;\n}\n\n@keyframes shake {\n\t10%,\n\t90% {\n\t\ttransform: translate3d(-1px, 0, 0);\n\t}\n\t20%,\n\t80% {\n\t\ttransform: translate3d(2px, 0, 0);\n\t}\n\t30%,\n\t50%,\n\t70% {\n\t\ttransform: translate3d(-4px, 0, 0);\n\t}\n\t40%,\n\t60% {\n\t\ttransform: translate3d(4px, 0, 0);\n\t}\n}\n", ""]);
// Exports
module.exports = exports;
