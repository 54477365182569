// React
import React from "react";
// Atoms
import Button from "Components/atoms/buttons/Button";

const StatusOfItem = (props) => {
  const { status, disabled, customSelectViewStyle } = props;

  return (
    <Button
      disabled={disabled}
      customStyle={{
        backgroundImage: `linear-gradient(45deg, #008D06, #008D06)`,
        fontWeight: "600",
        color: "white",
        display: "grid",
        marginLeft: customSelectViewStyle ? null : "7px",
        alignSelf: customSelectViewStyle ? "start" : null,
        fontSize: customSelectViewStyle ? "xx-small" : null,
        width: customSelectViewStyle ? "100%" : null,
        opacity: customSelectViewStyle ? "0.9" : null,
        borderRadius: customSelectViewStyle ? "0px 10px 0px 0px" : null,
      }}
      variant="contained"
      size="small"
      label={status}
      wrapText={true}
    />
  );
};

StatusOfItem.defaultProps = {
  status: "",
  disabled: true,
  customSelectViewStyle: false,
};

export default StatusOfItem;
