/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_ALL_ROWS_FOR_HOMESCREEN_REQ = "auth/GET_ALL_ROWS_FOR_HOMESCREEN_REQ";
const GET_ALL_ROWS_FOR_HOMESCREEN_SCS = "auth/GET_ALL_ROWS_FOR_HOMESCREEN_SCS";
const GET_ALL_ROWS_FOR_HOMESCREEN_FLR = "auth/GET_ALL_ROWS_FOR_HOMESCREEN_FLR";

const CLEAR_ROWS = "auth/CLEAR_ROWS";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const getAllRowsForHomeScreen = (lang) => async (dispatch) => {
  dispatch({ type: GET_ALL_ROWS_FOR_HOMESCREEN_REQ });
  const response = await getFunc(`homeScreenRow/getAllForGuest/${lang}`);
  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_ROWS_FOR_HOMESCREEN_SCS,
      payload: response.data,
    });
  } else {
    dispatch({ type: GET_ALL_ROWS_FOR_HOMESCREEN_FLR });
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const clearHSRows = () => async (dispatch) => {
  dispatch({ type: CLEAR_ROWS });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
  rows: {
    loading: false,
  },
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case CLEAR_ROWS:
      return {
        ...state,
        rows: {
          loading: false,
        },
      };
    case GET_ALL_ROWS_FOR_HOMESCREEN_REQ:
      return {
        ...state,
        rows: {
          loading: true,
        },
      };
    case GET_ALL_ROWS_FOR_HOMESCREEN_SCS:
      return {
        ...state,
        rows: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_ALL_ROWS_FOR_HOMESCREEN_FLR:
      return {
        ...state,
        rows: {
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
