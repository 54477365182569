module.exports = {
	// GLOBAL
	"GLOBAL.NOT_FOUND": "Non trouvé",
	"GLOBAL.INVALID_CODE_LABEL": "Code invalide !",
	"GLOBAL.FIRST_NAME_LABEL": "Prénom",
	"GLOBAL.LAST_NAME_LABEL": "Nom de famille",
	"GLOBAL.ROOM_NUMBER_LABEL": "Numéro de chambre",
	"GLOBAL.CLOSE_ACTION": "fermer",
	"GLOBAL.CONTINUE_ACTION": "continuer",
	"GLOBAL.CONFIRM_ACTION": "confirmateur",
	"GLOBAL.PROCEED_ACTION": "continuer",
	"GLOBAL.SUBMIT_ACTION": "soumettre",
	"GLOBAL.NEXT_ACTION": "suivant",
	"GLOBAL.BACK_ACTION": "retour",
	"GLOBAL.SKIP_ACTION": "ignorer",
	"GLOBAL.SEARCH": "Rechercher...",
	"GLOBAL.TOTAL_LABEL": "total",
	"GLOBAL.PAGE_HOME": "accueil",
	"GLOBAL.PAGE_BROWSE": "cherche",
	"GLOBAL.PAGE_ORDERS": "ordres",
	"GLOBAL.PAGE_CHAT": "chat",
	"GLOBAL.PAGE_PROFILE": "profil",
	"GLOBAL.CHOOSE_LANG_TITLE": "Bienvenue à",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_1":
		"Pour une meilleure expérience, veuillez sélectionner votre langue préférée",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_2": "Vous pouvez toujours le changer plus tard",
	"GLOBAL.SWW": "Une erreur s'est produite !",
	"GLOBAL.SWW_OPTION_1": "Actualiser",
	"GLOBAL.SWW_OPTION_2": "Aller à la page d'accueil",
	"GLOBAL.LANGUAGE": "Langue",
	"GLOBAL.SERVICES": "Services",
	"GLOBAL.ORDERS": "ordres",
	"GLOBAL.APPOINTMENTS": "rendez-vous",
	"GLOBAL.EVENTS": "événements",
	"GLOBAL.AUTRES": "AUTRES",
	"GLOBAL.QUANTITY": "Quantité",
	"GLOBAL.SERVICE": "Service",
	"GLOBAL.PROCESSING_PAYMENT": "Traitement du paiement...",

	// STATUSES
	"GLOBAL.STATUS_PROCESSING_LABEL": "Traitement",
	"GLOBAL.STATUS_UNSUCESSFULLY_PROCESSED_LABEL": "Échec du traitement",
	"GLOBAL.STATUS_FINISHED_LABEL": "Terminé",
	"GLOBAL.STATUS_PENDING_LABEL": "En attente",
	"GLOBAL.STATUS_CANCELED_LABEL": "Annulé",
	"GLOBAL.STATUS_IN_PROGRESS_LABEL": "En cours",

	// SEARCH
	"SEARCH.INPUT_LABEL": "Chercher",
	"SEARCH.NO_RESULTS": "Aucun résultat",
	"SEARCH.LOADING": "Chargement des résultats",
	"SEARCH.MORE_CHARS_LABEL": "Tapez {{chars}} caractères supplémentaires",

	// NOTIFICATIONS
	"NOTIFICATIONS.TITLE": "notifications",
	"NOTIFICATIONS.READ_ALL": "tout lire",

	// PROFILE
	"PROFILE.LOG_OUT": "Se déconnecter",
	"PROFILE.NO_ROOM_LABEL": "L'invité n'est pas affecté à la chambre",
	"PROFILE.CHECKOUT_LABEL": "Compte",
	"PROFILE.SUBSCRIPTIONS": "Mes abonnements",
	"PROFILE.SUBSCRIPTIONS_USER_INFO_LABEL":
		"Prénom et nom (numéro de chambre) :",
	"PROFILE.SUBSCRIPTIONS_DURATION_LABEL": "Durée",
	"PROFILE.NO_SUBSCRIPTIONS":
		"Il n'y a pas d'abonnement ! Veuillez vérifier la réception pour en obtenir un !",
	"PROFILE.BALANCE": "Mon solde",
	"PROFILE.MY_SPENDINGS": "Mes dépenses",
	"PROFILE.SPENDINGS": "Dépenses",
	"PROFILE.SPENT": "Dépensé",
	"PROFILE.MY_STAY": "Mon séjour",
	"PROFILE.RATE_STAY": "Évaluez votre séjour",
	"PROFILE.BAN": "BAN",
	"PROFILE.LIMITATION": "limitation",
	"PROFILE.SET_LIMIT": "Définir une limite",
	"PROFILE.ENTER_AMOUNT": "Entrez le montant",
	"PROFILE.HOW_TO_USE_APP": "Comment utiliser l'application ?",
	"PROFILE.TOTAL_ORDERS": "ORDRES",
	"PROFILE.ROOM_NUMBER": "Numéro de chambre",
	"PROFILE.NO_RESTRICTIONS": "Aucune restriction",
	"PROFILE.SET": "Régler",
	"PROFILE.RATE_ACCOMODATION": "Hébergement",
	"PROFILE.RATE_SERVICE": "Service",
	"PROFILE.RATE_REVIEW_TEXT": "Un avis",
	"PROFILE.RATE_TEXT_PLACEHOLDER": "Tapez ici...",
	"PROFILE.RATE_DELETE": "supprimer la note",

	// ORDERS
	"ORDERS.TITLE": "mes ordres & rendez-vous",
	"ORDERS.EMPTY_TXT":
		"Il n'y a pas de ordres! Passez une ordre et elle apparaîtra ici !",
	"ORDERS.CANCEL_ACTION": "annuler",
	"ORDERS.ITEM_SHOW_DETAILS": "Afficher les détails",
	"ORDERS.ITEM_FREE_LABEL": "Gratuit",
	"ORDERS.ITEM_RESERVED_LABEL": "RÉSERVÉ",
	"ORDERS.RATE_ACTION": "taux",
	"ORDERS.RATE_SERVICE_LABEL": "Service",
	"ORDERS.RATE_REVIEW_LABEL": "Un avis",
	"ORDERS.RATE_MSG_PLACEHOLDER": "Tapez ici...",
	"ORDERS.CANCEL_LABEL": "annuler la ordre",
	"ORDERS.CANCEL_TEXT": `Êtes-vous sûr de vouloir supprimer {{orderItemQuantity}} X {{orderItemName}} de la ordre?`,
	"ORDERS.CANCEL_CONFIRM": "oui, je suis sûr",
	"ORDERS.ON_ROOM_BILL_LABEL": "sur la facture de la chambre",
	"ORDERS.TIME_LEFT_FOR_DELIVERY":
		"Il reste {{time}} jusqu'à ce que l'article de la ordre soit prêt",
	"ORDERS.ITEM_PICK_UP_AT_LABEL": "Retrait à",

	// CHAT
	"CHAT.DISABLED": "Le chat est désactivé",
	"CHAT.NEW_CONVERSATION": "Ajouter une nouvelle conversation",
	"CHAT.REMOVE_CONVERSATION": "Supprimer la conversation",
	"CHAT.TYPE_MESSAGE": "Tapez un message ici",

	// BROWSE
	"BROWSE.TEMPLATE_ORDER_ORDER_LABEL": "choisir la quantité et les extras",
	"BROWSE.TEMPLATE_EVENT_ORDER_LABEL": "sélectionnez le nombre de billets",
	"BROWSE.TEMPLATE_DATE_RANGE_ORDER_LABEL": "réserver",
	"BROWSE.TEMPLATE_APPOINTMENT_ORDER_LABEL":
		//"Commencer le processus de réservation",
		"réserver",
	"BROWSE.REVIEWS_LABEL": "avis",
	"BROWSE.TEMPLATE_DATE_RANGE_SUBMIT_ALREADY_IN_CART_MSG":
		"Cet article est déjà dans le panier !",
	"BROWSE.TEMPLATE_APPOINTMENT_PICK_MSG": "Veuillez choisir un rendez-vous !",
	"BROWSE.ITEM_NOT_RATED": "pas noté",
	"BROWSE.ITEM_MAX_NUM_OF_RESERVATIONS":
		"Nombre max. de réservation par fois est ",
	"BROWSE.ADDED_TO_CART_MSG": "Ajouté au panier avec succès !",
	"BROWSE.ITEM_NO_PRICE_OR_READONLY_OR_DISABLED_ORDERING_MSG":
		"Actuellement, la prise de rendez-vous n'est pas disponible. Veuillez réessayer plus tard !",

	// CART
	"CART.TITLE": "mon panier",
	"CART.EMPTY_TITLE": "Votre panier est vide !",
	"CART.EMPTY_SUBTITLE":
		"Parcourez les articles pour commencer à les ajouter au panier",
	"CART.SCHEDULE_DELIVERY": "planifier la livraison",
	"CART.SCHEDULED_TIME_GREATER_THAN_CURRENT_TIME_MSG":
		"L'heure planifiée doit être supérieure à l'heure actuelle.",
	"CART.ORGANISATION_WORKING_TIME_FROM_MSG":
		"Le temps de travail de cette organisation est de",
	"CART.ORGANISATION_WORKING_TIME_TO_MSG": "à",
	"CART.SCHEDULED_TIME_HAS_TO_BE_AFTER_MSG":
		"Le délai de livraison doit être après",
	"CART.CHECKOUT_ACTION": "passer à la caisse",
	"CART.REMOVE_ALL_ACTION": "tout supprimer",
	"CART.TOTAL_LABEL": "TOTAL ARTICLES",
	"CART.ITEM_SHOW_DETAILS": "Afficher les détails",
	"CART.ITEM_FREE_LABEL": "Gratuit",
	"CART.ITEM_ROOM_SERVICE_LABEL": "Service en chambre",
	"CART.ITEM_PICK_UP_AT_LABEL": "Retrait à",
	"CART.ROOM_BILL_ACTION": "facture de la chambre",
	"CART.PAY_WITH_CARD_ACTION": "payer par carte",
	"CART.ORDER_SCS_TITLE": "Ordre passée avec succès !",
	"CART.ORDER_SCS_SUBTITLE":
		"Vous pouvez toujours suivre vos ordres dans la section ORDRES sous le menu principal.",
	"CART.ORDER_MORE_ACTION": "ordre plus",
	"CART.ORDER_MY_ORDERS_ACTION": "mes ordres",
	"CART.ORDER_EXCEEDED_LIMIT_MSG": "Vous avez dépassé la limite !",
	"CART.ORDER_CANCEL_OR_CONTINUE":
		"Voulez-vous annuler une action ou continuer ?",
	"CART.ORDER_DATE_TO_FROM_VALIDATION_MSG":
		"La date de doit être supérieure à la date de",
	"CART.ORDER_INVALID_ITEMS_MSG": "Le panier contient des articles invalides",
	"CART.ORDER_CARD_PAYMENT_MSG":
		"Le paiement par carte n'est pas encore implémenté !",
	//"CART.ORDER_SCHEDULED_TIME_PAST_MSG":"Scheduled date is in past",

	// RATE DIALOG
	"RATE_DIALOG.TITLE": "Évaluez notre application",
	"RATE_DIALOG.RATE_TEXT":
		"Votre avis nous aidera à améliorer GuestApp. S'il vous plaît, donnez-nous votre avis honnête !",
	"RATE_DIALOG.RATE_ACTION": "évaluer",
	"RATE_DIALOG.CANCEL_ACTION": "pas maintenant",
	"RATE_DIALOG.NO_STARS_MSG": "Veuillez sélectionner des étoiles de 1 à 5 !",

	// ORDER
	"ORDER.CHOOSE_QUANTITY": "Choisir la quantité",
	"ORDER.ADD_TO_CART_ACTION": "ajouter au panier",
	"ORDER.TEMPLATE_ORDER_EXTRAS": "Extras",
	"ORDER.TEMPLATE_ORDER_DELIVERY_TYPE": "Type de livraison",
	"ORDER.TEMPLATE_ORDER_ITEM_ROOM_SERVICE_LABEL": "Service en chambre",
	"ORDER.TEMPLATE_ORDER_ITEM_PICK_UP_AT_LABEL": "Retrait à",
	"ORDER.TEMPLATE_DATE_RANGE_STEP_1_TITLE":
		"Sélectionnez un ou plusieurs jours",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_1":
		"Choisissez la date et la quantité",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_2": "Choisir la date",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_2_TITLE": "Choisir l'heure exacte",
	"ORDER.TEMPLATE_APPOINTMENT_NO_MAX_CAPACITY_TXT":
		"Actuellement, la prise de rendez-vous n'est pas disponible. Veuillez réessayer plus tard !",
	"ORDER.TEMPLATE_DATE_RANGE_FROM_LABEL": "De",
	"ORDER.TEMPLATE_DATE_RANGE_TO_LABEL": "À",

	// TUTORIAL
	"TUTORIAL.SKIP": "ignorer le didacticiel",

	// VALIDATION
	"VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	//"VALIDATION.STAR_RATING_REQUIRED": "Star rating is required",
	"VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",
	"VALIDATION.STAY_REVIEW_DELETED_SCS":
		"Your review for stay is successfully deleted!",
	"VALIDATION.LIMIT_UPDATED_SCS": "Limit is successfully updated!",
	"VALIDATION.ORDER_ITEM_CANCEL_FLR": "You cannot cancel an order item",
	"VALIDATION.ORDER_ITEM_CANCEL_SCS": "Item is successfully canceled!",
	"VALIDATION.CALENDAR_AVAILABLE_TERMINS_FLR":
		"The calendar has no dates available",
	// "VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	// "VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",

	// OTHERS
	Popular: "Populaire",
	"special offer": "offre spéciale",
	"Special offer": "Offre spéciale",
	Ban: "interdire",
	Limitation: "limitation",
};
