/* eslint-disable camelcase */
// React
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Material-UI
import {
  Container,
  Box,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";

// Layout components
import Header from "Layouts/sections/Header";
import Footer from "Layouts/sections/Footer";

// Redux actions
import { getTheme } from "Modules/units/App";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

// Share icon
import iosShareIcon from "Assets/images/ios_share_icon.png";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const TripleLayout = (state) => {
  const [, windowHeight] = useWindowSize();

  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const [showInstallTut, setInstallTut] = useState(false);

  useEffect(() => {
    if (app.theme === null) dispatch(getTheme());
    if (isIos() && !isInStandaloneMode()) {
      setInstallTut(true);
    }
    return () => {};
  }, []);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () =>
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone ||
    document.referrer.includes("android-app://");

  const isHomePage = () => {
    if (history.location.pathname === "/home") {
      return true;
    } else return false;
  };

  return (
    <>
      {app.theme?.mui_theme ? (
        <MuiThemeProvider theme={createMuiTheme(app.theme.mui_theme)}>
          {/*showInstallTut && isHomePage() && (
            <Box
              className="ios-install-notification"
              onClick={() => setInstallTut(false)}
            >
              To <b>install</b> this webapp on your device; tap
              <img
                alt="iosshareicon"
                src={iosShareIcon}
                style={{
                  width: "20px",
                  height: "27px",
                  margin: "0 5px",
                }}
              />
              and then <b>Add to homescreen</b>.
            </Box>
              )*/}
          <Container
            fixed
            maxWidth="md"
            style={{
              height: isIos() ? windowHeight - 69 : windowHeight - 57,
              padding: "0",
            }}
          >
            {state.children}
          </Container>
          <Box
            style={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              boxShadow: "0px -7px 8px -7px rgba(0,0,0,0.20)",
              paddingBottom: isIos() ? "12px" : null,
              backgroundColor: "white",
              opacity: "0.95",
              zIndex: 9999,
            }}
          >
            <Container fixed maxWidth="md">
              <Footer />
            </Container>
          </Box>
        </MuiThemeProvider>
      ) : (
        <FullScreenSpinner />
      )}
    </>
  );
};

export default TripleLayout;
