import { MAIN_API, CHAT_API } from "Util/apiConfig";
// eslint-disable-next-line import/no-cycle
import { authHeader, checkToken } from "Util/authUtil";

function handleResponse(response) {
  if (!response.ok) {
    checkToken();
    return Promise.reject(response.statusText);
  }
  checkToken();
  return response.json();
}

export async function onGetSubscriptions() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(`${MAIN_API}/getSubscriptions`, options);
  const response = await fetch(request);
  return handleResponse(response);
}

export async function sendSubscriptionToServer(data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({
      data
    })
  };

  const request = new Request(`${MAIN_API}/saveSubscription`, options);
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onSendNotification(data) {
  const formData = new FormData();
  const jsonData = {
    title: data.title,
    description: data.description,
    guests: [data.selectedUser]
  };

  data.images.forEach(image => {
    formData.append("image", image);
  });

  data.icon.forEach(icon => {
    formData.append("icon", icon);
  });
  formData.append("data", JSON.stringify(jsonData));

  const options = {
    method: "POST",
    headers: {
      ...authHeader()
    },
    body: formData
  };

  const request = new Request(`${MAIN_API}/pushNotifications`, options);
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetMessagesByUser(id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({
      guest_id: id
    })
  };

  const request = new Request(`${CHAT_API}/admin/getMessages`, options);
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onGetUnreadNumber() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  const request = new Request(`${CHAT_API}/admin/unread`, options);
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onReadMessages(guestId) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({
      user_client_id: JSON.parse(sessionStorage.getItem("user_client_id"))
    })
  };

  const request = new Request(`${CHAT_API}/read/${guestId}`, options);
  const response = await fetch(request);
  return handleResponse(response);
}

export async function onUnselect() {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    },
    body: JSON.stringify({
      user_client_id: JSON.parse(sessionStorage.getItem("user_client_id"))
    })
  };

  const request = new Request(`${CHAT_API}/unselect`, options);
  const response = await fetch(request);
  return handleResponse(response);
}
