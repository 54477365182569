// React
import React from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
  },
}));

const ReviewStar = (props) => {
  const classes = useStyles();

  const { label, customStyle, color, dataCy } = props;

  return (
    <Typography className={classes.header} style={customStyle} dataCy={dataCy}>
      {label}
      <IconButton
        color={color}
        customStyle={{ marginTop: "-5px" }}
        icon={<StarIcon />}
        dataCy={dataCy}
      />
    </Typography>
  );
};

ReviewStar.defaultProps = {
  label: "",
  color: "primary",
  customStyle: {},
  dataCy: null,
};

export default ReviewStar;
