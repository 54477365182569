module.exports = {
	// GLOBAL
	"GLOBAL.NOT_FOUND": "Not found",
	"GLOBAL.INVALID_CODE_LABEL": "Invalid code!",
	"GLOBAL.FIRST_NAME_LABEL": "First name",
	"GLOBAL.LAST_NAME_LABEL": "Last name",
	"GLOBAL.ROOM_NUMBER_LABEL": "Room number",
	"GLOBAL.CLOSE_ACTION": "close",
	"GLOBAL.CONTINUE_ACTION": "continue",
	"GLOBAL.CONFIRM_ACTION": "confirm",
	"GLOBAL.PROCEED_ACTION": "proceed",
	"GLOBAL.SUBMIT_ACTION": "submit",
	"GLOBAL.NEXT_ACTION": "next",
	"GLOBAL.BACK_ACTION": "back",
	"GLOBAL.SKIP_ACTION": "skip",
	"GLOBAL.SEARCH": "Search...",
	"GLOBAL.TOTAL_LABEL": "total",
	"GLOBAL.PAGE_HOME": "home",
	"GLOBAL.PAGE_BROWSE": "browse",
	"GLOBAL.PAGE_ORDERS": "orders",
	"GLOBAL.PAGE_CHAT": "chat",
	"GLOBAL.PAGE_PROFILE": "profile",
	"GLOBAL.CHOOSE_LANG_TITLE": "Welcome to",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_1":
		"For best experience, please select your preferred language",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_2": "You can always change it later",
	"GLOBAL.SWW": "Something went wrong!",
	"GLOBAL.SWW_OPTION_1": "Refresh",
	"GLOBAL.SWW_OPTION_2": "Go to homepage",
	"GLOBAL.LANGUAGE": "Language",
	"GLOBAL.SERVICES": "Services",
	"GLOBAL.ORDERS": "orders",
	"GLOBAL.APPOINTMENTS": "appointments",
	"GLOBAL.EVENTS": "events",
	"GLOBAL.OTHERS": "OTHERS",
	"GLOBAL.QUANTITY": "Quantity",
	"GLOBAL.SERVICE": "Service",
	"GLOBAL.PROCESSING_PAYMENT": "Processing payment...",

	// STATUSES
	"GLOBAL.STATUS_PROCESSING_LABEL": "Processing",
	"GLOBAL.STATUS_UNSUCESSFULLY_PROCESSED_LABEL": "Unsucessfully processed",
	"GLOBAL.STATUS_FINISHED_LABEL": "Finished",
	"GLOBAL.STATUS_PENDING_LABEL": "Pending",
	"GLOBAL.STATUS_CANCELED_LABEL": "Canceled",
	"GLOBAL.STATUS_IN_PROGRESS_LABEL": "In progress",

	// SEARCH
	"SEARCH.INPUT_LABEL": "Search",
	"SEARCH.NO_RESULTS": "No results",
	"SEARCH.LOADING": "Loading results",
	"SEARCH.MORE_CHARS_LABEL": "Type {{chars}} more characters",

	// NOTIFICATIONS
	"NOTIFICATIONS.TITLE": "notifications",
	"NOTIFICATIONS.READ_ALL": "read all",

	// PROFILE
	"PROFILE.LOG_OUT": "Log out",
	"PROFILE.NO_ROOM_LABEL": "Guest is not assigned to Room",
	"PROFILE.CHECKOUT_LABEL": "Check out",
	"PROFILE.SUBSCRIPTIONS": "My subscriptions",
	"PROFILE.SUBSCRIPTIONS_USER_INFO_LABEL": "First and last name (room number):",
	"PROFILE.SUBSCRIPTIONS_DURATION_LABEL": "Duration",
	"PROFILE.NO_SUBSCRIPTIONS":
		"There is no any subscriptions! Please, check reception to get one!",
	"PROFILE.BALANCE": "My balance",
	"PROFILE.MY_SPENDINGS": "My spendings",
	"PROFILE.SPENDINGS": "Spendings",
	"PROFILE.SPENT": "Spent",
	"PROFILE.MY_STAY": "My stay",
	"PROFILE.RATE_STAY": "Rate your stay",
	"PROFILE.BAN": "BAN",
	"PROFILE.LIMITATION": "limitation",
	"PROFILE.SET_LIMIT": "Set a limit",
	"PROFILE.ENTER_AMOUNT": "Enter the amount",
	"PROFILE.HOW_TO_USE_APP": "How to use app?",
	"PROFILE.TOTAL_ORDERS": "ORDERS",
	"PROFILE.ROOM_NUMBER": "Room number",
	"PROFILE.NO_RESTRICTIONS": "No restrictions",
	"PROFILE.SET": "Set",
	"PROFILE.RATE_ACCOMODATION": "Accommodation",
	"PROFILE.RATE_SERVICE": "Service",
	"PROFILE.RATE_REVIEW_TEXT": "A review",
	"PROFILE.RATE_TEXT_PLACEHOLDER": "Type here...",
	"PROFILE.RATE_DELETE": "delete rating",

	// ORDERS
	"ORDERS.TITLE": "my orders & appointments",
	"ORDERS.EMPTY_TXT":
		"There isn't any orders! Make an order and it'll appear here!",
	"ORDERS.CANCEL_ACTION": "cancel",
	"ORDERS.ITEM_SHOW_DETAILS": "Show details",
	"ORDERS.ITEM_FREE_LABEL": "Free",
	"ORDERS.ITEM_RESERVED_LABEL": "RESERVED",
	"ORDERS.RATE_ACTION": "rate",
	"ORDERS.RATE_SERVICE_LABEL": "Service",
	"ORDERS.RATE_REVIEW_LABEL": "A review",
	"ORDERS.RATE_MSG_PLACEHOLDER": "Type here...",
	"ORDERS.CANCEL_LABEL": "cancel order",
	"ORDERS.CANCEL_TEXT": `Are you sure you want to remove {{orderItemQuantity}} X {{orderItemName}} from order?`,
	"ORDERS.CANCEL_CONFIRM": "yes, i am sure",
	"ORDERS.ON_ROOM_BILL_LABEL": "on room bill",
	"ORDERS.TIME_LEFT_FOR_DELIVERY": "{{time}} left until order item is ready",
	"ORDERS.ITEM_PICK_UP_AT_LABEL": "Pick up at",

	// CHAT
	"CHAT.DISABLED": "Chat is disabled",
	"CHAT.NEW_CONVERSATION": "Add new conversation",
	"CHAT.REMOVE_CONVERSATION": "Remove conversation",
	"CHAT.TYPE_MESSAGE": "Type message here",

	// BROWSE
	"BROWSE.TEMPLATE_ORDER_ORDER_LABEL": "choose quantity and extras",
	"BROWSE.TEMPLATE_EVENT_ORDER_LABEL": "select the number of tickets",
	"BROWSE.TEMPLATE_DATE_RANGE_ORDER_LABEL": "reserve",
	"BROWSE.TEMPLATE_APPOINTMENT_ORDER_LABEL": "start making an appointment",
	"BROWSE.REVIEWS_LABEL": "reviews",
	"BROWSE.TEMPLATE_DATE_RANGE_SUBMIT_ALREADY_IN_CART_MSG":
		"This item is already in the cart!",
	"BROWSE.TEMPLATE_APPOINTMENT_PICK_MSG": "Please pick an appointment!",
	"BROWSE.ITEM_NOT_RATED": "not rated",
	"BROWSE.ITEM_MAX_NUM_OF_RESERVATIONS":
		"Max. number of reservation by once is ",
	"BROWSE.ADDED_TO_CART_MSG": "Added to cart successfully!",
	"BROWSE.ITEM_NO_PRICE_OR_READONLY_OR_DISABLED_ORDERING_MSG":
		"Currently, making an apointment is not available. Please, try again later!",

	// CART
	"CART.TITLE": "my cart",
	"CART.EMPTY_TITLE": "Your cart is empty!",
	"CART.EMPTY_SUBTITLE": "Browse items to start adding them to cart",
	"CART.SCHEDULE_DELIVERY": "schedule delivery",
	"CART.SCHEDULED_TIME_GREATER_THAN_CURRENT_TIME_MSG":
		"Scheduled time has to be greater than current time.",
	"CART.ORGANISATION_WORKING_TIME_FROM_MSG":
		"Working time of this organisation is from",
	"CART.ORGANISATION_WORKING_TIME_TO_MSG": "to",
	"CART.SCHEDULED_TIME_HAS_TO_BE_AFTER_MSG": "Delivery time has to be after",
	"CART.CHECKOUT_ACTION": "checkout",
	"CART.REMOVE_ALL_ACTION": "remove all",
	"CART.TOTAL_LABEL": "TOTAL ITEMS",
	"CART.ITEM_SHOW_DETAILS": "Show details",
	"CART.ITEM_FREE_LABEL": "Free",
	"CART.ITEM_ROOM_SERVICE_LABEL": "Room service",
	"CART.ITEM_PICK_UP_AT_LABEL": "Pick up at",
	"CART.ROOM_BILL_ACTION": "room bill",
	"CART.PAY_WITH_CARD_ACTION": "pay with card",
	"CART.ORDER_SCS_TITLE": "Order successfully placed!",
	"CART.ORDER_SCS_SUBTITLE":
		"You can always track your orders in ORDERS section under main menu.",
	"CART.ORDER_MORE_ACTION": "order more",
	"CART.ORDER_MY_ORDERS_ACTION": "my orders",
	"CART.ORDER_EXCEEDED_LIMIT_MSG": "You have exceeded the limit!",
	"CART.ORDER_CANCEL_OR_CONTINUE": "Do you want cancel an action or continue?",
	"CART.ORDER_DATE_TO_FROM_VALIDATION_MSG":
		"Date to must be greater than date from",
	"CART.ORDER_INVALID_ITEMS_MSG": "Cart contains invalid items",
	"CART.ORDER_CARD_PAYMENT_MSG": "Card payment is not implemented yet!",
	//"CART.ORDER_SCHEDULED_TIME_PAST_MSG":"Scheduled date is in past",

	// RATE DIALOG
	"RATE_DIALOG.TITLE": "Rate our app",
	"RATE_DIALOG.RATE_TEXT":
		"Your review will help us improve GuestApp. Please, give us your honest opinon!",
	"RATE_DIALOG.RATE_ACTION": "rate",
	"RATE_DIALOG.CANCEL_ACTION": "not now",
	"RATE_DIALOG.NO_STARS_MSG": "Please select stars from 1 to 5!",

	// ORDER
	"ORDER.CHOOSE_QUANTITY": "Choose quantity",
	"ORDER.ADD_TO_CART_ACTION": "add to cart",
	"ORDER.TEMPLATE_ORDER_EXTRAS": "Extras",
	"ORDER.TEMPLATE_ORDER_DELIVERY_TYPE": "Delivery type",
	"ORDER.TEMPLATE_ORDER_ITEM_ROOM_SERVICE_LABEL": "Room service",
	"ORDER.TEMPLATE_ORDER_ITEM_PICK_UP_AT_LABEL": "Pick up at",
	"ORDER.TEMPLATE_DATE_RANGE_STEP_1_TITLE": "Select one or more days",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_1": "Choose date and quantity",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_2": "Choose date",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_2_TITLE": "Pick exact time",
	"ORDER.TEMPLATE_APPOINTMENT_NO_MAX_CAPACITY_TXT":
		"Currently, making an apointment is not available. Please, try again later!",
	"ORDER.TEMPLATE_DATE_RANGE_FROM_LABEL": "From",
	"ORDER.TEMPLATE_DATE_RANGE_TO_LABEL": "To",

	// TUTORIAL
	"TUTORIAL.SKIP": "skip tutorial",

	// VALIDATION
	"VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	//"VALIDATION.STAR_RATING_REQUIRED": "Star rating is required",
	"VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",
	"VALIDATION.ITEM_REVIEW_SCS": "Review is successfully saved !",
	"VALIDATION.STAY_REVIEW_DELETED_SCS":
		"Your review for stay is successfully deleted!",
	"VALIDATION.LIMIT_UPDATED_SCS": "Limit is successfully updated!",
	"VALIDATION.ORDER_ITEM_CANCEL_FLR": "You cannot cancel an order item",
	"VALIDATION.ORDER_ITEM_CANCEL_SCS": "Item is successfully canceled!",
	"VALIDATION.CALENDAR_AVAILABLE_TERMINS_FLR":
		"The calendar has no dates available",
	// "VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	// "VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",

	// OTHERS
	Popular: "Popular",
	"special offer": "special offer",
	"Special offer": "Special offer",
	Ban: "ban",
	Limitation: "limitation",
};
