// React
import React from "react";

// Atoms
import Button from "Components/atoms/buttons/Button";

const Tag = (props) => {
	const { size, label, customStyle, priceExists } = props;

	return (
		<Button
			variant="contained"
			disabled
			customStyle={Object.assign(
				{
					backgroundImage: `linear-gradient(45deg, #008D06, #008D06)`,
					fontWeight: "600",
					marginLeft: priceExists ? "8px" : 0 /*matches && '8px'*/,
				},
				customStyle
			)}
			size="small"
			label={label}
			wrapText={true}
		/>
	);
};

Tag.defaultProps = {
	disabled: true,
	priceExists: true,
	size: "small",
	label: "",
	customStyle: {},
};

export default Tag;
