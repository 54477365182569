// Localizations components
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Languages
import en from "./languages/en";
import hr from "./languages/hr";
import de from "./languages/de";
import fr from "./languages/fr";
import it from "./languages/it";

import { getCookie } from "Util/functions";

const resources = {
	en: {
		translation: en,
	},
	hr: {
		translation: hr,
	},
	de: {
		translation: de,
	},
	fr: {
		translation: fr,
	},
	it: {
		translation: it,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: getCookie("lang") || "en",

	keySeparator: false,

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
