// React
import React from "react";

// MUI
import IconButtonMUI from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

const IconButton = (props) => {
  const { onClick, color, icon, customStyle, size, disabled, dataCy } = props;

  return (
    <IconButtonMUI
      disabled={disabled}
      color={color}
      size={size}
      onClick={(e) => {
        onClick(e);
      }}
      style={customStyle}
      dataCy={dataCy}
    >
      <Icon>{icon}</Icon>
    </IconButtonMUI>
  );
};

IconButton.defaultProps = {
  onClick: () => console.log("Clicked!"),
  color: "primary",
  icon: "mood",
  customStyle: {},
  size: "small",
  disabled: false,
  dataCy: null,
};

export default IconButton;
