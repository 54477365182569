// React
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// MUI
import { Typography, Box, Divider, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

// Atoms
import Price from "Components/atoms/UI/Price";
import Button from "Components/atoms/buttons/Button";

//Molecules
import NumericInput from "Components/molecules/AlternativeNumericInput";

//Util
import { GLOBAL_VARIABLES } from "Util/idConfig";
import { getNameFromList } from "Util/functions";

const Accordion = withStyles({
	root: {
		//position:"absolute",
		zIndex: 1,
		padding: 0,
		margin: 0,
		border: 0,
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: 0,
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		margin: 0,
		padding: 0,
		backgroundColor: "rgba(0, 0, 0, .03)",
		borderRadius: "10px",
		minHeight: 20,
		"&$expanded": {
			minHeight: 20,
		},
	},
	content: {
		margin: 0,
		padding: 0,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&$expanded": {
			margin: 0,
			padding: 0,
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		display: "flex",
		flexDirection: "column",
	},
}))(MuiAccordionDetails);

const TimelineContent = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const theme = useTheme();

	const [expanded, setExpanded] = useState(null);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const lists = useSelector((state) => state.lists);

	const handleClick = (event, id, name, orderItemName, orderItemQuantity) => {
		setParentState(id, name, orderID, orderItemName, orderItemQuantity);
	};

	const {
		image,
		component,
		title,
		price,
		extras,
		quantity,
		id,
		rateOrder,
		setParentState,
		currency,
		classes,
		displayDate,
		date,
		dateTo,
		organisation,
		template,
		status,
		organisations,
		orderID,
		serviceID,
		pickupType,
		deliveryOrgName,
		timeline,
	} = props;

	function colorCharacter(id) {
		if (organisations?.length > 0) {
			let color_temp;
			let char_temp;
			organisations?.map((i) => {
				if (i.id === id) {
					color_temp = i.color;
					char_temp = i.name.split(" ")[0];
				}
			});
			return (
				<Box
					className={classes.itemSize}
					style={{
						/*alignSelf: "start",
            position: "absolute",*/
						backgroundColor: color_temp,
						textTransform: "uppercase",
						fontWeight: "bold",
						fontSize: "10px",
						color: "#fff",
						border: "2px solid #fff",
						borderRadius: "0px 0px 0px 10px",
						margin: "0px",
						padding: "0px",
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRight: 0,
					}}
				>
					{char_temp}
				</Box>
			);
		}
	}

	return (
		<>
			<div className={classes.containerTimeline}>
				<div className={classes.cartItemHeaderTimeline}>
					<div
						className={classes.imgTimeline}
						style={{ backgroundImage: `url(${image})` }}
						onClick={() =>
							history.push("/singleview/" + serviceID + "/" + null)
						}
					>
						{quantity && <NumericInput quantity={quantity} justValue={true} />}
					</div>
					<div className={classes.organisationTimeline}>
						{colorCharacter(organisation)}
					</div>
				</div>
				<div className={classes.cartItemDescriptionTimeline}>
					<div className={classes.titleTimeline}>
						{/* {displayDate && (
                <div
                  className={classes.wrapTextStyle}
                  // style={{ whiteSpace: "break-spaces" }}
                >
                  <Typography variant="caption">
                    {t("Purchase date")}: {moment(displayDate).format("ll")}
                  </Typography>
                  <hr
                    className={classes.horizontal_line_dialog}
                    width={"40px"}
                  />
                </div>
              )} */}

						{/* (template === GLOBAL_VARIABLES.templates.appointment ||
											template === GLOBAL_VARIABLES.templates.date_picker ||
											template === GLOBAL_VARIABLES.templates.date_range) &&
										new Date(dateTo).getTime() > new Date().getTime() */}
						<Typography className={classes.titleTextTimeline}>
							{t(title)}
						</Typography>
						{timeline &&
							(status ===
								GLOBAL_VARIABLES.order_item_status.find(
									(i) => i.name === "GLOBAL.STATUS_FINISHED_LABEL"
								).id &&
							(template === GLOBAL_VARIABLES.templates.appointment ||
								template === GLOBAL_VARIABLES.templates.date_picker ||
								template === GLOBAL_VARIABLES.templates.date_range) &&
							new Date(dateTo).getTime() >
								new Date().getTime() ? null : status ===
							  GLOBAL_VARIABLES.order_item_status.find(
									(i) => i.name === "GLOBAL.STATUS_FINISHED_LABEL"
							  ).id ? (
								<Button
									label={t("ORDERS.RATE_ACTION").toUpperCase()}
									onClick={(event) =>
										handleClick(event, id, "rate", title, quantity)
									}
									customStyle={{
										color: theme.palette.primary.main,
										padding: "0px 4px",
										fontWeight: 600,
										borderRadius: "4px",
										backgroundImage: "linear-gradient(#e7e9ed, #e7e9ed)",
										boxShadow: theme.shadows[1],
									}}
								/>
							) : status !==
									GLOBAL_VARIABLES.order_item_status.find(
										(i) => i.name === "GLOBAL.STATUS_CANCELED_LABEL"
									).id &&
							  status !==
									GLOBAL_VARIABLES.order_item_status.find(
										(i) =>
											i.name === "GLOBAL.STATUS_UNSUCESSFULLY_PROCESSED_LABEL"
									).id ? (
								<Button
									label={t("ORDERS.CANCEL_ACTION").toUpperCase()}
									onClick={(event) =>
										handleClick(event, id, "cancel", title, quantity)
									}
									customStyle={{
										color: theme.palette.primary.main,
										padding: "0px 4px",
										fontWeight: 600,
										borderRadius: "4px",
										backgroundImage: "linear-gradient(#e7e9ed, #e7e9ed)",
										boxShadow: theme.shadows[1],
									}}
								/>
							) : null)}
					</div>

					<div className={classes.infoTimeline}>
						{extras.length > 0 || pickupType ? (
							<Accordion
								square
								expanded={expanded === orderID}
								onChange={handleChange(orderID)}
							>
								<AccordionSummary aria-controls="order" id="order-header">
									{expanded === orderID ? (
										<KeyboardArrowUpIcon fontSize="small" color="default" />
									) : (
										<KeyboardArrowDownIcon fontSize="small" color="default" />
									)}
									<Typography className={classes.infoExtrasTitleTimeline}>
										{t("ORDERS.ITEM_SHOW_DETAILS")}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography className={classes.infoExtrasTextTimeline}>
										{extras?.map((i) => {
											return (
												t(i.name) +
												(i.charge_as_side_dish
													? ` (${i.price?.toFixed(2)} ${
															currency ? currency : ""
													  })`
													: ` (${t("ORDERS.ITEM_FREE_LABEL")})`) +
												", "
											);
										})}
										{extras.length > 0 && pickupType ? (
											<Divider></Divider>
										) : null}
									</Typography>
									<Typography variant="caption">
										{`${
											pickupType
												? getNameFromList(
														lists,
														"Delivery type",
														pickupType
												  ) === "Pickup organisations"
													? t("ORDERS.ITEM_PICK_UP_AT_LABEL")
													: t("ORDERS.ITEM_ROOM_SERVICE_LABEL")
												: ""
										} ${deliveryOrgName ? `@ ${deliveryOrgName}` : ""}`}
									</Typography>
								</AccordionDetails>
							</Accordion>
						) : null}
						<Typography className={classes.infoDateText}>
							{/* {displayDate &&
                    template !== GLOBAL_VARIABLES.templates.order && (
                      <>
                        <Typography variant="caption">
                          {t("Reserved for")}
                        </Typography>
                        <br />
                      </>
                    )} */}

							{(template === GLOBAL_VARIABLES.templates.date_picker ||
								template === GLOBAL_VARIABLES.templates.date_range) &&
								date &&
								moment(date).format("ll")}
							{/* {template === GLOBAL_VARIABLES.templates.date_range &&
                  date &&
                  dateTo
                    ? moment(date).format("ll") === moment(dateTo).format("ll")
                      ? moment(date).format("ll")
                      : moment(date).format("ll") +
                        " - " +
                        moment(dateTo).format("ll")
                    : null} */}
							{template === GLOBAL_VARIABLES.templates.appointment &&
								date &&
								moment(date).format("lll")}
						</Typography>
					</div>

					<div className={classes.labelsTimeline}>
						{price > 0 && (
							<Price
								customStyle={{
									padding: "0 3px",
									fontSize: "13px",
								}}
								label={
									(price ? price.toFixed(2) : "") +
									" " +
									(currency ? currency : "")
								}
							/>
						)}

						{rateOrder && (
							<p
								style={{
									margin: "3px",
									fontFamily: "Rubik",
									fontWeight: 600,
									color:
										status ===
											GLOBAL_VARIABLES.order_item_status.find(
												(i) => i.name === "GLOBAL.STATUS_FINISHED_LABEL"
											).id &&
										(template === GLOBAL_VARIABLES.templates.appointment ||
											template === GLOBAL_VARIABLES.templates.date_picker ||
											template === GLOBAL_VARIABLES.templates.date_range) &&
										new Date(dateTo).getTime() > new Date().getTime()
											? GLOBAL_VARIABLES.order_item_status.find(
													(i) => i.name === "GLOBAL.STATUS_IN_PROGRESS_LABEL"
											  ).color
											: GLOBAL_VARIABLES.order_item_status.find(
													(i) => i.id === status
											  ).color,
									textAlign: "right",
								}}
							>
								{status ===
									GLOBAL_VARIABLES.order_item_status.find(
										(i) => i.name === "GLOBAL.STATUS_FINISHED_LABEL"
									).id &&
								(template === GLOBAL_VARIABLES.templates.appointment ||
									template === GLOBAL_VARIABLES.templates.date_picker ||
									template === GLOBAL_VARIABLES.templates.date_range) &&
								new Date(dateTo).getTime() > new Date().getTime()
									? t("ORDERS.ITEM_RESERVED_LABEL")
									: t(
											GLOBAL_VARIABLES.order_item_status.find(
												(i) => i.id === status
											).name
									  ).toUpperCase()}
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

TimelineContent.defaultProps = {
	image: "",
	component: "img",
	title: "Title",
	price: "",
	extras: [],
	quantity: 1,
	id: "",
	rateOrder: false,
	currency: null,
	classes: {},
	displayDate: null,
	date: null,
	dateTo: null,
	organisation: null,
	template: null,
	status: null,
	organisations: null,
	orderID: null,
	serviceID: null,
};

export default TimelineContent;
