/* eslint-disable camelcase */
// React
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material-UI
import { Container, MuiThemeProvider, createMuiTheme } from "@material-ui/core";

// Redux actions
import { getTheme } from "Modules/units/App";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

const BaseLayout = ({ children }) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);

	useEffect(() => {
		if (app.theme === null) dispatch(getTheme());
		return () => {};
	}, []);

	return (
		<>
			{app.theme?.mui_theme ? (
				<MuiThemeProvider theme={createMuiTheme(app.theme.mui_theme)}>
					<Container fixed maxWidth="xl">
						{children}
					</Container>
				</MuiThemeProvider>
			) : (
				<FullScreenSpinner />
			)}
		</>
	);
};

export default BaseLayout;
