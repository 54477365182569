/**
 * IMPORTS
 */

import { getFunc, postFunc } from "Services/mainApiServices";

/**
 * TYPES
 */

const GET_THEME = "auth/GET_THEME";

const GET_ACTIVE_LANGUAGES = "auth/GET_ACTIVE_LANGUAGES";

const SAVE_DEVICE = "auth/SAVE_DEVICE";

const CLEAR_THEME = "auth/CLEAR_THEME";

/**
 * ACTIONS
 */

export const getTheme = (lang, func = () => {}) => async (dispatch) => {
	// let lang;
	// const value = `; ${document.cookie}`;
	// const parts = value.split(`; lang=`);
	// if (parts.length === 2) {
	//   lang = parts
	//     .pop()
	//     .split(";")
	//     .shift();
	// }
	const response = await getFunc(
		`tenants/templateDetails${lang ? `?lang=${lang}` : ``}`
	);

	if (response.status.errorCode === 200) {
		localStorage.setItem("theme", JSON.stringify(response.data));
		localStorage.setItem("tutorials", JSON.stringify(response.tutorials));
		localStorage.setItem(
			"welcome_message",
			JSON.stringify(response.welcome_message)
		);
		dispatch({
			type: GET_THEME,
			payload: response,
		});
		func();
	}
};

export const getActiveLanguages = () => async (dispatch) => {
	const response = await getFunc(`tenantLanguage/getAllActive`);

	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ACTIVE_LANGUAGES,
			payload: response.data,
		});
	}
};

export const saveDevice = (info) => async (dispatch) => {
	// const body = {
	//   device: info,
	//   user_client_id: JSON.parse(sessionStorage.getItem("user_client_id")),
	// };
	const response = await postFunc(`saveDevice`, {
		device: info,
		user_client_id: JSON.parse(sessionStorage.getItem("user_client_id")),
	});

	if (response.status.errorCode === 200) {
		dispatch({
			type: SAVE_DEVICE,
		});
	}
};

export const clearTheme = () => async (dispatch) => {
	dispatch({ type: CLEAR_THEME });
};

/**
 * REDUCERS
 */

const INIT_STATE = {
	theme: JSON.parse(localStorage.getItem("theme")),
	activeLangs: [],
	tutorials: JSON.parse(localStorage.getItem("tutorials")),
	welcome_message: JSON.parse(localStorage.getItem("welcome_message")),
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case GET_THEME:
			return {
				...state,
				theme: action.payload.data,
				tutorials: action.payload.tutorials,
				welcome_message: action.payload.welcome_message,
			};
		case GET_ACTIVE_LANGUAGES:
			return {
				...state,
				activeLangs: action.payload,
			};
		case CLEAR_THEME:
			return {
				...state,
				theme: null,
			};
		default:
			return state;
	}
}
