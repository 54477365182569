// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// MUI
import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles(() => ({
  logo: { verticalAlign: "middle" },
  tag: {
    padding: "2px 8px",
    display: "inline",
    borderRadius: "5px",
    marginLeft: "5px",
    fontWeight: 500,
    fontSize: "12px",
    verticalAlign: "sub",
  },
}));

const Logo = (props) => {
  const classes = style();
  const logo = useSelector((state) => state.app.theme.logo);

  const { width, goTo } = props;

  return (
    <a href={goTo} style={{ textDecoration: "none" }}>
      <img alt="logo" src={logo} className={classes.logo} style={{ width }} />
    </a>
  );
};

Logo.defaultProps = {
  width: "100%",
  goTo: "/",
  tag: "",
};

export default Logo;
