// React
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";

// MUI
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";

// Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";

// Atoms
import Button from "Components/atoms/buttons/Button";
import { Typography } from "@material-ui/core";

// Actions
import { rateOurApp } from "Modules/units/Rate";
import shadows from "@material-ui/core/styles/shadows";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	rating: {
		display: "flow-root",
		boxShadow: theme.shadows[2],
		borderRadius: "10px",
		marginTop: "10px",
		height: "60px",
		textAlign: "center",
		paddingTop: "6px",
	},
	ratingStar: {
		color: theme.palette.primary.main,
	},
}));

const RateApp = ({ opened, handleClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ratingSubmitted = useSelector((state) => state.rate?.loading);

	const [stars, onSetStarts] = useState(null);
	const [text, onSetText] = useState(null);

	const handleRating = () => {
		if (stars) {
			dispatch(
				rateOurApp({
					review_point: stars,
					review_description: text !== "" ? text : null,
				})
			);
		} else {
			NotificationManager.error(t("RATE_DIALOG.NO_STARS_MSG"));
		}
	};

	return (
		<div>
			<DialogMUI TransitionComponent={Transition} open={opened}>
				<div style={{ padding: "15px", paddingBottom: "0px" }}>
					<TitleWithDivider
						title={t("RATE_DIALOG.TITLE")}
						size="18px"
						width="40px"
					/>
					<Typography>{t("RATE_DIALOG.RATE_TEXT")}</Typography>
					<Box className={classes.rating}>
						<Rating
							className={classes.ratingStar}
							style={{
								fontSize: "-webkit-xxx-large",
							}}
							// name={item.id}
							value={stars}
							onChange={(e) => onSetStarts(e.target.value)}
						/>
					</Box>
					<br />
					<TextField
						multiline
						rows={4}
						placeholder={t("ORDERS.RATE_MSG_PLACEHOLDER")}
						variant="outlined"
						fullWidth
						value={text === null ? "" : text}
						onChange={(e) => onSetText(e.target.value)}
					/>
				</div>

				<DialogActions
					style={{ display: "block", padding: "15px", paddingTop: "0px" }}
				>
					<Button
						onClick={handleRating}
						label={t("RATE_DIALOG.RATE_ACTION")}
						variant="contained"
						disabled={ratingSubmitted}
						loading={ratingSubmitted}
						customStyle={{
							width: "100%",
							height: "48px",
							borderRadius: "10px",
							marginTop: "10px",
							marginLeft: "0px",
							backgroundImage: "linear-gradient(#00A2BA, #00A2BA)",
							fontWeight: 600,
							color: "#fff",
						}}
					/>
					<Button
						onClick={handleClose}
						label={t("RATE_DIALOG.CANCEL_ACTION")}
						variant="contained"
						customStyle={{
							width: "100%",
							height: "48px",
							borderRadius: "10px",
							fontWeight: "bold",
							margin: "0px",
							marginTop: "8px",
							fontWeight: 600,
						}}
						customFontColor={true}
					/>
				</DialogActions>
			</DialogMUI>
		</div>
	);
};

export default RateApp;
