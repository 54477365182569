/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { GLOBAL_VARIABLES } from "Util/idConfig";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const ADD_ITEMS_TO_CART = "auth/ADD_ITEMS_TO_CART";
const CLEAR_CART = "auth/CLEAR_CART";

const GET_ALL_ITEMS_FROM_CART = "auth/GET_ALL_ITEMS_FROM_CART";
const SET_LOCAL_STORAGE_ITEMS_IN_REDUX =
  "auth/SET_LOCAL_STORAGE_ITEMS_IN_REDUX";

const DURATION = "auth/DURATION";
const CLEAR_DURATION = "auth/CLEAR_DURATION";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const addItemsToCart = (data, successfulFunc) => async (dispatch) => {
  dispatch({ type: ADD_ITEMS_TO_CART, data2: data });
  dispatch({ type: CLEAR_DURATION });
  successfulFunc ? successfulFunc() : null;
};

export const clearCart = () => async (dispatch) => {
  dispatch({ type: CLEAR_CART });
  localStorage.removeItem("order");
};

export const setLocalStorageItemsInRedux = (data) => async (dispatch) => {
  dispatch({ type: SET_LOCAL_STORAGE_ITEMS_IN_REDUX, data: data });
};

export const getAllItemsFromCart = (data) => async (dispatch) => {
  dispatch({ type: GET_ALL_ITEMS_FROM_CART, data: data });
};

export const duration = (val) => async (dispatch) => {
  dispatch({ type: DURATION, payload: val });
};

export const clearDuration = () => async (dispatch) => {
  dispatch({ type: CLEAR_DURATION });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
  items: {
    data: [],
  },
  duration: null,
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case ADD_ITEMS_TO_CART:
      const addedItem = state.items.data.concat(action.data2);

      const uniqueByID = addedItem.reduce((accumulator, current) => {
        if (
          current?.data?.category?.template === GLOBAL_VARIABLES.templates.order
        ) {
          if (
            !accumulator?.some(
              (i) =>
                i?.data?.id === current?.data?.id &&
                JSON.stringify(i.extras) === JSON.stringify(current.extras)
            )
          ) {
            accumulator.push(current);
          } else if (
            accumulator?.some((i) => i?.data?.id === current?.data?.id)
          ) {
            const temp = accumulator?.find(
              (i) =>
                i?.data?.id === current?.data?.id &&
                JSON.stringify(i.extras) === JSON.stringify(current.extras)
            );

            return accumulator.map((j) => {
              if (temp.unique_id === j.unique_id) {
                return { ...temp, quantity: temp.quantity + current.quantity };
              } else return j;
            });
          }

          return accumulator;
        } else {
          accumulator.push(current);
        }
        return accumulator;
      }, []);

      localStorage.setItem(
        "order",
        JSON.stringify(uniqueByID)
      );
      return {
        ...state,
        items: {
          data: uniqueByID,
        },
      };
    case CLEAR_CART:
      return {
        ...state,
        items: {
          data: [],
        },
      };
    case SET_LOCAL_STORAGE_ITEMS_IN_REDUX:
      return {
        ...state,
        items: {
          data: action.data,
        },
      };
    case GET_ALL_ITEMS_FROM_CART:
      return {
        ...state,
        items: {
          data: action.data,
        },
      };
    case DURATION:
      return {
        ...state,
        duration: action.payload,
      };
    case CLEAR_DURATION:
      return {
        ...state,
        duration: null,
      };
    default:
      return state;
  }
}
