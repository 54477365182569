/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const SAVE_NOTIFICATIONS_TO_REDUX = "SAVE_NOTIFICATIONS_TO_REDUX";

/**
 * ACTIONS
 */

export const getNotifications = (lang) => async (dispatch) => {
  const response = await getFunc(`notifications/unread?lang=${lang}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: SAVE_NOTIFICATIONS_TO_REDUX,
      payload: response.data,
    });
    localStorage.setItem("notifications", JSON.stringify(response.data));
  } else {
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const markNotificationsAsRecieved = (data, body) => async (dispatch) => {
  const response = await putFunc("notifications/recieved", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: SAVE_NOTIFICATIONS_TO_REDUX, payload: data });
  } else {
    NotificationManager.error(i18n.t(response.status.description));
  }
};

export const markNotificationsAsRead = (data, body) => async (dispatch) => {
  const response = await putFunc("notifications/read", body);

  if (response.status.errorCode === 200) {
    dispatch({ type: SAVE_NOTIFICATIONS_TO_REDUX, payload: data });
  } else {
    NotificationManager.error(i18n.t(response.status.description));
  }
};

/**
 * REDUCERS
 */
const INIT_STATE = {
  list: JSON.parse(localStorage.getItem("notifications")) || [],
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case SAVE_NOTIFICATIONS_TO_REDUX:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
}
