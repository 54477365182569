// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// Atoms
import Button from "Components/atoms/buttons/Button";

// Image
import { NoOrdersImg } from "Components/atoms/imgs/NoOrdersImg";
import { useHistory } from "react-router-dom";

const EmptyCart = ({ closeCart }) => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<>
			<Box
				style={{
					textAlign: "center",
				}}
			>
				<NoOrdersImg />
				<Typography variant="body1">{t("CART.EMPTY_TITLE")}</Typography>
				<Typography variant="body1">{t("CART.EMPTY_SUBTITLE")}</Typography>
				<Button
					label={t("GLOBAL.PAGE_BROWSE").toUpperCase()}
					icon="search"
					customStyle={{
						height: "32px",
						fontWeight: 600,
						borderRadius: "10px",
						color: "#fff",
					}}
					variant={"contained"}
					onClick={() => {
						closeCart();
						setTimeout(() => {
							history.push("/browse");
						}, 500);
					}}
				/>
			</Box>
		</>
	);
};

EmptyCart.defaultProps = {};

export default EmptyCart;
