// Redux
import { combineReducers } from "redux";

// Units
import app from "Modules/units/App";
import auth from "Modules/units/Auth";
import validation from "Modules/units/Validation";
import browse from "Modules/units/Browse";
import home from "Modules/units/Home";
import order from "Modules/units/Order";
import cart from "Modules/units/Cart";
import chat from "Modules/units/Chat";
import lists from "Modules/units/Lists";
import rate from "Modules/units/Rate";
import notifications from "Modules/units/Notification";

export default combineReducers({
	app,
	auth,
	browse,
	cart,
	chat,
	home,
	notifications,
	order,
	rate,
	lists,
	validation,
});
