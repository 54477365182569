// React
import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { CardMedia, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

const NotificationContent = (props) => {
  const { t } = useTranslation();

  const {
    image,
    component,
    title,
    handleRemoveItem,
    descriptionText,
    classes,
    readOrUnread,
  } = props;

  return (
    <>
      {image && (
        <CardMedia
          className={classes.coverWithoutHeight}
          image={image}
          component={component}
          title={t(title)}
        />
      )}
      <div className={classes.details}>
        <div className={classes.wrapper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography className={classes.header}>{t(title)}</Typography>
              <div>
                <Typography
                  className={classes.description}
                  style={{ marginRight: "10px" }}
                >
                  {descriptionText}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexFlow: "column",
                minHeight: "105px",
              }}
            >
              {/* <IconButton
                icon={<Close />}
                color="default"
                onClick={handleRemoveItem}
              /> */}
              {readOrUnread ? <span className={classes.redDot} /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

NotificationContent.defaultProps = {
  image: "",
  component: "img",
  title: "Title",
  handleRemoveItem: () => console.log("remove!"),
  descriptionText: "",
  classes: {},
  readOrUnread: false,
};

export default NotificationContent;
