// React
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Material UI
import DialogMUI from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";

//Atoms
import Button from "Components/atoms/buttons/Button";

// Image
import { SuccessfulOrderImg } from "Components/atoms/imgs/SuccessfulOrderImg";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	imageStyle: {
		height: "35vh",
	},
}));

const FullscreenDialogSuccess = ({ opened, handleClose }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const clickedCategory = JSON.parse(localStorage.getItem("clickedCategory"));
	const theme = useTheme();

	const isXsDevice = useMediaQuery("(max-width:331px)");

	return (
		<div>
			<DialogMUI
				fullScreen
				open={opened}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<Box style={{ textAlign: "center", marginTop: "20px" }}>
					<Typography style={{ fontSize: "xx-large" }}>
						{t("CART.ORDER_SCS_TITLE")}
					</Typography>
				</Box>
				<Box style={{ height: !isXsDevice ? "45vh" : "35vh" }}>
					<SuccessfulOrderImg />
				</Box>
				<Box
					style={{
						textAlign: "center",
						marginTop: "5px",
						marginBottom: "10px",
					}}
				>
					<Typography
						style={{
							position: "relative",
							fontSize: "large",
							width: !isXsDevice ? "75%" : "100%",
							margin: "auto",
						}}
					>
						{t("CART.ORDER_SCS_SUBTITLE")}
					</Typography>
				</Box>
				<DialogActions
					style={{
						display: "block",
						position: "fixed",
						bottom: "7px",
						left: "7px",
						right: "7px",
					}}
				>
					<Button
						onClick={() => {
							handleClose();
							clickedCategory
								? history.push("/selectviewcollapse/" + clickedCategory.id)
								: null;
						}}
						label={"CART.ORDER_MORE_ACTION"}
						variant="contained"
						customStyle={{
							width: "100%",
							height: "48px",
							borderRadius: "10px",
							fontWeight: "bold",
							color: theme.palette.secondary.main,
							backgroundImage: "linear-gradient(#fff, #fff)",
						}}
					/>
					<Button
						onClick={() => {
							handleClose();
							history.push("/orders");
						}}
						label={"CART.ORDER_MY_ORDERS_ACTION"}
						variant="contained"
						customStyle={{
							width: "100%",
							height: "48px",
							borderRadius: "10px",
							marginTop: "10px",
							marginLeft: "0px",
							fontWeight: "bold",
							backgroundImage: "linear-gradient(#00A2BA, #00A2BA)",
							color: "#fff",
						}}
					/>
				</DialogActions>
			</DialogMUI>
		</div>
	);
};

export default FullscreenDialogSuccess;
