// React
import React from "react";

// Router
import { Router, Route, Switch } from "react-router-dom";
import MainRouter from "Router/MainRouter";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "react-error-boundary";

// Notification manager
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

// HMR
import { hot } from "react-hot-loader";

// Redux
import { Provider } from "react-redux";
import configureStore from "Modules/configureStore";

// Theme
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "Styles/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import "Styles/App.css";

// Localization
import "./localization/i18n";

// Page
import SwwPage from "Components/molecules/SwwPage.js";

require("babel-polyfill");

const history = createBrowserHistory();

function ErrorFallback({}) {
  return (
    <SwwPage
      resetErrorBoundary={() => {
        window.location.reload();
      }}
      goToHome={() => {
        history.push("/");
        window.location.reload();
      }}
    />
  );
}

const App = () => {
  sessionStorage.setItem("user_client_id", JSON.stringify(createUUID()));

  // eslint-disable-next-line class-methods-use-this
  function createUUID() {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = [];
    const hexDigits = "0123456789abcdef";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    // eslint-disable-next-line no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // eslint-disable-next-line no-multi-assign
    s[8] = s[13] = s[18] = s[23] = "-";

    const uuid = s.join("");
    return uuid;
  }

  return (
    <Provider store={configureStore()}>
      <NotificationContainer />
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Route path="/" component={MainRouter} />
            </ErrorBoundary>
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
};

export default hot(module)(App);
