/* eslint-disable camelcase */
// React
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material-UI
import {
	Container,
	Box,
	MuiThemeProvider,
	createTheme,
} from "@material-ui/core";

// Layout components
import HeaderCollapsibleSearchBar from "./HeaderCollapsibleSearchBar";
import Footer from "Layouts/sections/Footer";

// Redux actions
import { getTheme } from "Modules/units/App";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

// Share icon
import shadows from "@material-ui/core/styles/shadows";

const TripleAlternativeLayout = (state) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);

	useEffect(() => {
		if (app.theme === null) dispatch(getTheme());
		// if (isIos() && !isInStandaloneMode()) {
		// 	setInstallTut(true);
		// }
		return () => {};
	}, []);

	const isIos = () => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	};

	const isInStandaloneMode = () =>
		window.matchMedia("(display-mode: standalone)").matches ||
		window.navigator.standalone ||
		document.referrer.includes("android-app://");

	return (
		<>
			{app.theme?.mui_theme ? (
				<MuiThemeProvider theme={createTheme(app.theme.mui_theme)}>
					<HeaderCollapsibleSearchBar />
					<Container fixed maxWidth="md">
						<Box mt={12} mb={10}>
							{state.children}
						</Box>
					</Container>
					<Box
						style={{
							width: "100%",
							position: "fixed",
							bottom: 0,
							boxShadow: shadows[3],
							paddingBottom: isIos() ? "12px" : null,
							backgroundColor: "white",
							opacity: "0.95",
						}}
					>
						<Container fixed maxWidth="md">
							<Footer />
						</Container>
					</Box>
				</MuiThemeProvider>
			) : (
				<FullScreenSpinner />
			)}
		</>
	);
};

export default TripleAlternativeLayout;
