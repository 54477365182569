module.exports = {
	// GLOBAL
	"GLOBAL.NOT_FOUND": "Non trovato",
	"GLOBAL.INVALID_CODE_LABEL": "Codice non valido!",
	"GLOBAL.FIRST_NAME_LABEL": "Nome",
	"GLOBAL.LAST_NAME_LABEL": "Cognome",
	"GLOBAL.ROOM_NUMBER_LABEL": "Numero stanza",
	"GLOBAL.CLOSE_ACTION": "chiudi",
	"GLOBAL.CONTINUE_ACTION": "continua",
	"GLOBAL.CONFIRM_ACTION": "Confermare",
	"GLOBAL.PROCEED_ACTION": "procedi",
	"GLOBAL.SUBMIT_ACTION": "invia",
	"GLOBAL.NEXT_ACTION": "successivo",
	"GLOBAL.BACK_ACTION": "indietro",
	"GLOBAL.SKIP_ACTION": "salta",
	"GLOBAL.SEARCH": "Cerca...",
	"GLOBAL.TOTAL_LABEL": "totale",
	"GLOBAL.PAGE_HOME": "iniziale",
	"GLOBAL.PAGE_BROWSE": "sfoglia",
	"GLOBAL.PAGE_ORDERS": "ordini",
	"GLOBAL.PAGE_CHAT": "chat",
	"GLOBAL.PAGE_PROFILE": "profilo",
	"GLOBAL.CHOOSE_LANG_TITLE": "Benvenuto in",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_1":
		"Per una migliore esperienza, seleziona la tua lingua preferita",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_2": "Puoi sempre cambiarlo più tardi",
	"GLOBAL.SWW": "Qualcosa è andato storto!",
	"GLOBAL.SWW_OPTION_1": "Aggiorna",
	"GLOBAL.SWW_OPTION_2": "Vai alla home page",
	"GLOBAL.LANGUAGE": "Lingua",
	"GLOBAL.SERVICES": "Servizi",
	"GLOBAL.ORDERS": "ordini",
	"GLOBAL.APPUNTMENTS": "appuntamenti",
	"GLOBAL.EVENTS": "eventi",
	"GLOBAL.OTHERS": "ALTRI",
	"QUANTITÀ.GLOBALE": "Quantità",
	"GLOBAL.SERVICE": "Servizio",
	"GLOBAL.PROCESSING_PAYMENT": "Elaborazione del pagamento...",

	// STATUSES
	"GLOBAL.STATUS_PROCESSING_LABEL": "Elaborazione",
	"GLOBAL.STATUS_UNSUCESSFULLY_PROCESSED_LABEL": "Elaborato senza successo",
	"GLOBAL.STATUS_FINISHED_LABEL": "Finito",
	"GLOBAL.STATUS_PENDING_LABEL": "In attesa",
	"GLOBAL.STATUS_CANCELED_LABEL": "Annullato",
	"GLOBAL.STATUS_IN_PROGRESS_LABEL": "In corso",

	// SEARCH
	"SEARCH.INPUT_LABEL": "Ricerca",
	"SEARCH.NO_RESULTS": "Nessun risultato",
	"SEARCH.LOADING": "Risultati di caricamento",
	"SEARCH.MORE_CHARS_LABEL": "Digita altri {{chars}} caratteri",

	// NOTIFICATIONS
	"NOTIFICATIONS.TITLE": "notifiche",
	"NOTIFICATIONS.READ_ALL": "leggi tutto",

	// PROFILE
	"PROFILE.LOG_OUT": "Esci",
	"PROFILE.NO_ROOM_LABEL": "L'ospite non è assegnato alla camera",
	"PROFILE.CHECKOUT_LABEL": "Check out",
	"PROFILE.SUBSCRIPTIONS": "I miei abbonamenti",
	"PROFILE.SUBSCRIPTIONS_USER_INFO_LABEL": "Nome e cognome (numero stanza):",
	"PROFILE.SUBSCRIPTIONS_DURATION_LABEL": "Durata",
	"PROFILE.NO_SUBSCRIPTIONS":
		"Non ci sono abbonamenti! Per favore, controlla la reception per averne uno!",
	"PROFILE.BALANCE": "Il mio saldo",
	"PROFILE.MY_SPENDINGS": "Le mie spese",
	"PROFILE.SPENDINGS": "Spese",
	"PROFILE.SPENT": "Esaurito",
	"PROFILE.MY_STAY": "Il mio soggiorno",
	"PROFILE.RATE_STAY": "Valuta il tuo soggiorno",
	"PROFILE.BAN": "BAN",
	"PROFILE.LIMITATION": "limitazione",
	"PROFILE.SET_LIMIT": "Imposta un limite",
	"PROFILE.ENTER_AMOUNT": "Inserisci l'importo",
	"PROFILE.HOW_TO_USE_APP": "Come si usa l'app?",
	"PROFILE.TOTAL_ORDERS": "ORDINI",
	"PROFILE.ROOM_NUMBER": "Numero stanza",
	"PROFILE.NO_RESTRICTIONS": "Nessuna restrizione",
	"PROFILE.SET": "Imposta",
	"PROFILE.RATE_ACCOMODATION": "Alloggio",
	"PROFILE.RATE_SERVICE": "Servizio",
	"PROFILE.RATE_REVIEW_TEXT": "Una recensione",
	"PROFILE.RATE_TEXT_PLACEHOLDER": "Digita qui...",
	"PROFILE.RATE_DELETE": "elimina valutazione",

	// ORDERS
	"ORDERS.TITLE": "i miei ordini e appuntamenti",
	"ORDERS.EMPTY_TXT": "Non ci sono ordini! Fai un ordine e apparirà qui!",
	"ORDERS.CANCEL_ACTION": "annulla",
	"ORDERS.ITEM_SHOW_DETAILS": "Mostra dettagli",
	"ORDERS.ITEM_FREE_LABEL": "Gratuito",
	"ORDERS.ITEM_RESERVED_LABEL": "RISERVATO",
	"ORDERS.RATE_ACTION": "tariffa",
	"ORDERS.RATE_SERVICE_LABEL": "Servizio",
	"ORDERS.RATE_REVIEW_LABEL": "Una recensione",
	"ORDERS.RATE_MSG_PLACEHOLDER": "Digita qui...",
	"ORDERS.CANCEL_LABEL": "annulla ordine",
	"ORDERS.CANCEL_TEXT": `Sei sicuro di voler rimuovere {{orderItemQuantity}} X {{orderItemName}} dall'ordine?`,
	"ORDERS.CANCEL_CONFIRM": "sì, sono sicuro",
	"ORDERS.ON_ROOM_BILL_LABEL": "sul conto della camera",
	"ORDERS.TIME_LEFT_FOR_DELIVERY":
		"{{time}} rimanenti prima che l'articolo dell'ordine sia pronto",
	"ORDERS.ITEM_PICK_UP_AT_LABEL": "Ritiro a",

	// CHAT
	"CHAT.DISABLED": "La chat è disabilitata",
	"CHAT.NEW_CONVERSATION": "Aggiungi nuova conversazione",
	"CHAT.REMOVE_CONVERSATION": "Rimuovi conversazione",
	"CHAT.TYPE_MESSAGE": "Digita il messaggio qui",

	// BROWSE
	"BROWSE.TEMPLATE_ORDER_ORDER_LABEL": "scegli quantità ed extra",
	"BROWSE.TEMPLATE_EVENT_ORDER_LABEL": "seleziona il numero di biglietti",
	"BROWSE.TEMPLATE_DATE_RANGE_ORDER_LABEL": "riserva",
	"BROWSE.TEMPLATE_APPOINTMENT_ORDER_LABEL": "inizia a fissare un appuntamento",
	"BROWSE.REVIEWS_LABEL": "recensioni",
	"BROWSE.TEMPLATE_DATE_RANGE_SUBMIT_ALREADY_IN_CART_MSG":
		"Questo articolo è già nel carrello!",
	"BROWSE.TEMPLATE_APPOINTMENT_PICK_MSG": "Scegli un appuntamento!",
	"BROWSE.ITEM_NOT_RATED": "non valutato",
	"BROWSE.ITEM_MAX_NUM_OF_RESERVATIONS":
		"Il numero massimo di prenotazioni per una volta è ",
	"BROWSE.ADDED_TO_CART_MSG": "Aggiunto al carrello con successo!",
	"BROWSE.ITEM_NO_PRICE_OR_READONLY_OR_DISABLED_ORDERING_MSG":
		"Attualmente non è possibile fissare un appuntamento. Per favore, riprova più tardi!",

	// CART
	"CART.TITLE": "il mio carrello",
	"CART.EMPTY_TITLE": "Il tuo carrello è vuoto!",
	"CART.EMPTY_SUBTITLE":
		"Sfoglia gli articoli per iniziare ad aggiungerli al carrello",
	"CART.SCHEDULE_DELIVERY": "programma consegna",
	"CART.SCHEDULED_TIME_GREATER_THAN_CURRENT_TIME_MSG":
		"L'ora programmata deve essere maggiore dell'ora corrente.",
	"CART.ORGANISATION_WORKING_TIME_FROM_MSG":
		"L'orario di lavoro di questa organizzazione è da",
	"CART.ORGANISATION_WORKING_TIME_TO_MSG": "a",
	"CART.SCHEDULED_TIME_HAS_TO_BE_AFTER_MSG":
		"Il tempo di consegna deve essere successivo",
	"CART.CHECKOUT_ACTION": "checkout",
	"CART.REMOVE_ALL_ACTION": "rimuovi tutto",
	"CART.TOTAL_LABEL": "TOTALE ARTICOLI",
	"CART.ITEM_SHOW_DETAILS": "Mostra dettagli",
	"CART.ITEM_FREE_LABEL": "Gratuito",
	"CART.ITEM_ROOM_SERVICE_LABEL": "Servizio in camera",
	"CART.ITEM_PICK_UP_AT_LABEL": "Ritiro a",
	"CART.ROOM_BILL_ACTION": "conto della camera",
	"CART.PAY_WITH_CARD_ACTION": "paga con carta",
	"CART.ORDER_SCS_TITLE": "Ordine effettuato con successo!",
	"CART.ORDER_SCS_SUBTITLE":
		"Puoi sempre tracciare i tuoi ordini nella sezione ORDINI nel menu principale.",
	"CART.ORDER_MORE_ACTION": "ordina altro",
	"CART.ORDER_MY_ORDERS_ACTION": "i miei ordini",
	"CART.ORDER_EXCEEDED_LIMIT_MSG": "Hai superato il limite!",
	"CART.ORDER_CANCEL_OR_CONTINUE": "Vuoi annullare un'azione o continuare?",
	"CART.ORDER_DATE_TO_FROM_VALIDATION_MSG":
		"La data a deve essere maggiore di data da",
	"CART.ORDER_INVALID_ITEMS_MSG": "Il carrello contiene articoli non validi",
	"CART.ORDER_CARD_PAYMENT_MSG":
		"Il pagamento con carta non è ancora implementato!",
	//"CART.ORDER_SCHEDULED_TIME_PAST_MSG":"Scheduled date is in past",

	// RATE DIALOG
	"RATE_DIALOG.TITLE": "Valuta la nostra app",
	"RATE_DIALOG.RATE_TEXT":
		"La tua recensione ci aiuterà a migliorare GuestApp. Per favore, dacci la tua onesta opinione!",
	"RATE_DIALOG.RATE_ACTION": "Vota",
	"RATE_DIALOG.CANCEL_ACTION": "non ora",
	"RATE_DIALOG.NO_STARS_MSG": "Seleziona stelle da 1 a 5!",

	// ORDER
	"ORDER.CHOOSE_QUANTITY": "Scegli quantità",
	"ORDER.ADD_TO_CART_ACTION": "aggiungi al carrello",
	"ORDER.TEMPLATE_ORDER_EXTRAS": "Extras",
	"ORDER.TEMPLATE_ORDER_DELIVERY_TYPE": "Tipo di consegna",
	"ORDER.TEMPLATE_ORDER_ITEM_ROOM_SERVICE_LABEL": "Servizio in camera",
	"ORDER.TEMPLATE_ORDER_ITEM_PICK_UP_AT_LABEL": "Ritiro a",
	"ORDER.TEMPLATE_DATE_RANGE_STEP_1_TITLE": "Seleziona uno o più giorni",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_1": "Scegli data e quantità",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_2": "Scegli data",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_2_TITLE": "Scegli l'ora esatta",
	"ORDER.TEMPLATE_APPOINTMENT_NO_MAX_CAPACITY_TXT":
		"Attualmente non è possibile fissare un appuntamento. Per favore, riprova più tardi!",
	"ORDER.TEMPLATE_DATE_RANGE_FROM_LABEL": "Da",
	"ORDER.TEMPLATE_DATE_RANGE_TO_LABEL": "A",

	// TUTORIAL
	"TUTORIAL.SKIP": "salta tutorial",

	// VALIDATION
	"VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	//"VALIDATION.STAR_RATING_REQUIRED": "Star rating is required",
	"VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",
	"VALIDATION.STAY_REVIEW_DELETED_SCS":
		"Your review for stay is successfully deleted!",
	"VALIDATION.LIMIT_UPDATED_SCS": "Limit is successfully updated!",
	"VALIDATION.ORDER_ITEM_CANCEL_FLR": "You cannot cancel an order item",
	"VALIDATION.ORDER_ITEM_CANCEL_SCS": "Item is successfully canceled!",
	"VALIDATION.CALENDAR_AVAILABLE_TERMINS_FLR":
		"The calendar has no dates available",
	// "VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	// "VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",

	// OTHERS
	Popular: "Popolare",
	"special offer": "offerta speciale",
	"Special offer": "Offerta speciale",
	Ban: "divieto",
	Limitation: "limitazione",
};
