// React
import React from "react";
import { useTranslation } from "react-i18next";

import LoadingOverlay from "react-loading-overlay";
import { GridLoader } from "react-spinners";

const ProcessingSpinner = ({ showSpinner }) => {
	const { t } = useTranslation();
	return (
		<>
			{showSpinner ? (
				<LoadingOverlay
					styles={{
						wrapper: {
							position: "fixed",
							top: "0",
							left: "0",
							background: "#fff",
							//opacity: 0.8,
							zIndex: 998,
							height: "100%",
							width: "100%",
						},
						overlay: {
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							color: "white",
							background: "#fff",
							//opacity: 0.8,
							zIndex: 1000,
						},
						content: {
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-evenly",
							alignItems: "center",
							textAlign: "center",
							color: "#00A2BA",
							fontSize: "16px",
							minHeight: "20vh",
						},
					}}
					active={showSpinner}
					spinner={<GridLoader color="#00A2BA" />}
					text={t("GLOBAL.PROCESSING_PAYMENT")}
				></LoadingOverlay>
			) : null}
		</>
	);
};

export default ProcessingSpinner;
