// React
import React from "react";

// Atoms
import Button from "Components/atoms/buttons/Button";

const Price = (props) => {
  const { size, label, disabled, customStyle } = props;

  return (
    <Button
      variant="contained"
      disabled={disabled}
      customStyle={Object.assign({ fontWeight: "600" }, customStyle)}
      size={size}
      label={label}
      wrapText={true}
    />
  );
};

Price.defaultProps = {
  disabled: true,
  size: "small",
  label: "",
  customStyle: {},
};

export default Price;
