/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const GET_ALL_ACTIVE_CATEGORIES_REQ = "auth/GET_ALL_ACTIVE_CATEGORIES_REQ";
const GET_ALL_ACTIVE_CATEGORIES_SCS = "auth/GET_ALL_ACTIVE_CATEGORIES_SCS";
const GET_ALL_ACTIVE_CATEGORIES_FLR = "auth/GET_ALL_ACTIVE_CATEGORIES_FLR";

const GET_ALL_ACTIVE_SUBCATEGORIES_REQ =
	"auth/GET_ALL_ACTIVE_SUBCATEGORIES_REQ";
const GET_ALL_ACTIVE_SUBCATEGORIES_SCS =
	"auth/GET_ALL_ACTIVE_SUBCATEGORIES_SCS";
const GET_ALL_ACTIVE_SUBCATEGORIES_FLR =
	"auth/GET_ALL_ACTIVE_SUBCATEGORIES_FLR";

const GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_REQ =
	"auth/GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_REQ";
const GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_SCS =
	"auth/GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_SCS";
const GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_FLR =
	"auth/GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_FLR";

const GET_ONE_ITEM_FROM_SELECTLIST_REQ =
	"auth/GET_ONE_ITEM_FROM_SELECTLIST_REQ";
const GET_ONE_ITEM_FROM_SELECTLIST_SCS =
	"auth/GET_ONE_ITEM_FROM_SELECTLIST_SCS";
const GET_ONE_ITEM_FROM_SELECTLIST_FLR =
	"auth/GET_ONE_ITEM_FROM_SELECTLIST_FLR";

const VIEW_AVAILABLE_TERMINS_REQ = "auth/VIEW_AVAILABLE_TERMINS_REQ";
const VIEW_AVAILABLE_TERMINS_SCS = "auth/VIEW_AVAILABLE_TERMINS_SCS";
const VIEW_AVAILABLE_TERMINS_FLR = "auth/VIEW_AVAILABLE_TERMINS_FLR";

const OPEN_AVAILABLE_TERMINS = "auth/OPEN_AVAILABLE_TERMINS";
const CLOSE_AVAILABLE_TERMINS = "auth/CLOSE_AVAILABLE_TERMINS";

const GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_REQ =
	"auth/GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_REQ";
const GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_SCS =
	"auth/GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_SCS";
const GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_FLR =
	"auth/GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_FLR";

const CLEAR_ALL = "auth/CLEAR_ALL";
const CLEAR_CATEGORIES = "auth/CLEAR_CATEGORIES";
const CLEAR_ONE_ITEM = "auth/CLEAR_ONE_ITEM";

const GET_QUANTITY_FOR_DATE_RANGE_REQ = "auth/GET_QUANTITY_FOR_DATE_RANGE_REQ";
const GET_QUANTITY_FOR_DATE_RANGE_SCS = "auth/GET_QUANTITY_FOR_DATE_RANGE_SCS";
const GET_QUANTITY_FOR_DATE_RANGE_FLR = "auth/GET_QUANTITY_FOR_DATE_RANGE_FLR";

const GET_AVAILABLE_FOR_DATE_RANGE_REQ =
	"auth/GET_AVAILABLE_FOR_DATE_RANGE_REQ";
const GET_AVAILABLE_FOR_DATE_RANGE_SCS =
	"auth/GET_AVAILABLE_FOR_DATE_RANGE_SCS";
const GET_AVAILABLE_FOR_DATE_RANGE_FLR =
	"auth/GET_AVAILABLE_FOR_DATE_RANGE_FLR";

const GET_ORGS_FOR_PICKUP = "auth/GET_ORGS_FOR_PICKUP";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const getAllOrgsWithAtLeastOneItem = (cat_id) => async (dispatch) => {
	dispatch({ type: GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_REQ });
	const response = await getFunc(
		`organisations/getOrganisationWithService/${cat_id}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getAllActiveCategories = (lang) => async (dispatch) => {
	dispatch({ type: GET_ALL_ACTIVE_CATEGORIES_REQ });
	const response = await getFunc(
		`category/guest/getAllActiveForLoggedUser/${lang}`
	);
	if (response.status.errorCode === 200) {
		dispatch({ type: GET_ALL_ACTIVE_CATEGORIES_SCS, payload: response.data });
	} else {
		dispatch({ type: GET_ALL_ACTIVE_CATEGORIES_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getAllActiveSubcategories = (lang, org_id, cat_id) => async (
	dispatch
) => {
	dispatch({ type: GET_ALL_ACTIVE_SUBCATEGORIES_REQ });
	const response = await getFunc(
		`subCategory/getAllActiveByOrganisationAndCategory/${lang}/${org_id}/${cat_id}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_ACTIVE_SUBCATEGORIES_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ALL_ACTIVE_SUBCATEGORIES_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getAllItemsByChosenOptions = (
	lang,
	org_id,
	cat_id,
	subcat_id
) => async (dispatch) => {
	dispatch({ type: GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_REQ });
	const response = await getFunc(
		`service/selectView/${lang}/${org_id}?category=${cat_id}&sub_category=${subcat_id}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_SCS,
			payload: [response.data, subcat_id],
		});
	} else {
		dispatch({ type: GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getOneItem = (lang, id, forStatistics) => async (dispatch) => {
	dispatch({ type: GET_ONE_ITEM_FROM_SELECTLIST_REQ });

	const response = await getFunc(
		`service/singleView/${lang}/${id}?source=${forStatistics}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ONE_ITEM_FROM_SELECTLIST_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ONE_ITEM_FROM_SELECTLIST_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const viewAvailableTermins = (
	service_id,
	date,
	chosenCapacity,
	func
) => async (dispatch) => {
	dispatch({ type: VIEW_AVAILABLE_TERMINS_REQ });
	const response = await getFunc(
		`orderItems/intervals?service=${service_id}&date=${date}&quantity=${chosenCapacity}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: VIEW_AVAILABLE_TERMINS_SCS,
			payload: response.data,
		});
		// dispatch(openAvailableTermins());
		func();
	} else {
		dispatch({ type: VIEW_AVAILABLE_TERMINS_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const openAvailableTermins = () => async (dispatch) => {
	dispatch({ type: OPEN_AVAILABLE_TERMINS });
};
export const closeAvailableTermins = () => async (dispatch) => {
	dispatch({ type: CLOSE_AVAILABLE_TERMINS });
};
export const clearSubcatAndItems = () => async (dispatch) => {
	dispatch({ type: CLEAR_ALL });
};
export const clearCategories = () => async (dispatch) => {
	dispatch({ type: CLEAR_CATEGORIES });
};
export const clearOneItem = () => async (dispatch) => {
	dispatch({ type: CLEAR_ONE_ITEM });
};

export const getQuantityForDateRange = (
	serviceId,
	date,
	dateTo,
	func
) => async (dispatch) => {
	dispatch({ type: GET_QUANTITY_FOR_DATE_RANGE_REQ });

	const response = await getFunc(
		`orderItems/checkFreeServiceByDateRange?service=${serviceId}&date_from=${date}&date_to=${dateTo}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_QUANTITY_FOR_DATE_RANGE_SCS,
			payload: response.data,
		});
		func();
	} else {
		dispatch({ type: GET_QUANTITY_FOR_DATE_RANGE_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getAvailableDatesForDateRange = (
	serviceId,
	dateFrom,
	dateTo
) => async (dispatch) => {
	dispatch({ type: GET_AVAILABLE_FOR_DATE_RANGE_REQ });

	const response = await getFunc(
		`orderItems/checkAvailableDates?service=${serviceId}&date_from=${dateFrom}&date_to=${dateTo}`
	);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_AVAILABLE_FOR_DATE_RANGE_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_AVAILABLE_FOR_DATE_RANGE_FLR });
		//NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getOrgsForPickup = () => async (dispatch) => {
	const response = await getFunc(`organisations/pickupPermission`);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ORGS_FOR_PICKUP,
			payload: response.data,
		});
	} else {
		NotificationManager.error(i18n.t(response.status.description));
	}
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
	orgs: {
		loading: false,
	},
	categories: {
		loading: false,
	},
	subcategories: {
		loading: false,
	},
	items: {
		loading: false,
	},
	oneItem: {
		loading: false,
	},
	available: {
		loading: false,
	},
	openAvailableTermins: false,
	maxQuantity: null,
	availableDates: [],
	pickupOrgs: [],
	loading: false,
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case CLEAR_ONE_ITEM:
			return {
				...state,
				oneItem: {
					loading: false,
				},
			};
		case CLEAR_CATEGORIES:
			return {
				...state,
				categories: {
					loading: false,
				},
			};
		case CLEAR_ALL:
			return {
				...state,
				subcategories: {
					loading: false,
				},
				items: {
					loading: false,
				},
				orgs: {
					loading: false,
				},
			};
		case GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_REQ:
			return {
				...state,
				orgs: {
					loading: true,
				},
			};
		case GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_SCS:
			return {
				...state,
				orgs: {
					loading: false,
					data: action.payload,
				},
			};

		case GET_QUANTITY_FOR_DATE_RANGE_FLR:
			return {
				...state,
				loading: false,
			};
		case GET_ALL_ACTIVE_CATEGORIES_REQ:
			return {
				...state,
				categories: {
					loading: true,
				},
			};
		case GET_ALL_ACTIVE_CATEGORIES_SCS:
			return {
				...state,
				categories: {
					loading: false,
					data: action.payload.sort((a, b) => a.position - b.position),
				},
			};
		case GET_ALL_ACTIVE_CATEGORIES_FLR:
			return {
				...state,
				categories: {
					loading: false,
					error: action.error,
				},
			};
		case GET_ALL_ACTIVE_SUBCATEGORIES_REQ:
			return {
				...state,
				subcategories: {
					...state.subcategories,
					loading: true,
					data: [],
				},
				items: {
					loading: false,
				},
			};
		case GET_ALL_ACTIVE_SUBCATEGORIES_SCS:
			return {
				...state,
				subcategories: {
					...state.subcategories,
					loading: false,
					data: action.payload.sort((a, b) => a.position - b.position),
				},
			};
		case GET_ALL_ACTIVE_SUBCATEGORIES_FLR:
			return {
				...state,
				subcategories: {
					loading: false,
					error: action.error,
				},
			};
		case GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_REQ:
			return {
				...state,
				items: {
					loading: true,
				},
			};
		case GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_SCS:
			return {
				...state,
				items: {
					loading: false,
					data: action.payload[0].sort((a, b) => a.position - b.position),
					displayImages:
						state.subcategories?.data[
							state?.subcategories?.data?.findIndex(
								(el) => el.id === action.payload[1]
							)
						]?.enable_images,
				},
			};
		case GET_ALL_ITEMS_BY_CHOSEN_OPTIONS_FLR:
			return {
				...state,
				items: {
					loading: false,
					error: action.error,
				},
			};
		case GET_ONE_ITEM_FROM_SELECTLIST_REQ:
			return {
				...state,
				oneItem: {
					loading: true,
				},
			};
		case GET_ONE_ITEM_FROM_SELECTLIST_SCS:
			return {
				...state,
				oneItem: {
					loading: false,
					data: action.payload,
				},
			};
		case GET_ONE_ITEM_FROM_SELECTLIST_FLR:
			return {
				...state,
				oneItem: {
					loading: false,
					error: action.error,
				},
			};
		case VIEW_AVAILABLE_TERMINS_REQ:
			return {
				...state,
				available: {
					loading: true,
				},
			};
		case VIEW_AVAILABLE_TERMINS_SCS:
			return {
				...state,
				available: {
					loading: false,
					data: action.payload,
				},
			};
		case VIEW_AVAILABLE_TERMINS_FLR:
			return {
				...state,
				available: {
					loading: false,
					error: action.error,
				},
			};
		case OPEN_AVAILABLE_TERMINS:
			return {
				...state,
				viewAvailableTermins: true,
			};
		case CLOSE_AVAILABLE_TERMINS:
			return {
				...state,
				viewAvailableTermins: false,
			};
		case GET_ALL_ORGS_WITH_AT_LEAST_ONE_ITEM_FLR:
			return {
				...state,
				orgs: {
					loading: false,
					error: action.error,
				},
			};
		case GET_QUANTITY_FOR_DATE_RANGE_REQ:
			return {
				...state,
				loading: true,
			};
		case GET_QUANTITY_FOR_DATE_RANGE_SCS:
			return {
				...state,
				maxQuantity: action.payload,
				loading: false,
			};
		case GET_AVAILABLE_FOR_DATE_RANGE_SCS:
			return {
				...state,
				availableDates: action.payload,
			};
		case GET_ORGS_FOR_PICKUP:
			return {
				...state,
				pickupOrgs: action.payload,
			};
		default:
			return state;
	}
}
