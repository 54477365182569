/**
|--------------------------------------------------
| IMPORTS
|--------------------------------------------------
*/

import { getFunc, postFunc, putFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import i18n from "i18next";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/

const ROOM_BILL_REQ = "auth/ROOM_BILL_REQ";
const ROOM_BILL_SCS = "auth/ROOM_BILL_SCS";
const ROOM_BILL_FLR = "auth/ROOM_BILL_FLR";

const GET_ALL_ORDERS_REQ = "auth/GET_ALL_ORDERS_REQ";
const GET_ALL_ORDERS_SCS = "auth/GET_ALL_ORDERS_SCS";
const GET_ALL_ORDERS_FLR = "auth/GET_ALL_ORDERS_FLR";

const CLOSE_CHECKOUT_DIALOG = "auth/CLOSE_CHECKOUT_DIALOG";
const CLEAR_CART = "auth/CLEAR_CART";
const CLEAR_ERRORS = "auth/CLEAR_ERRORS";
const OPEN_EXCEEDED_LIMIT_DIALOG = "auth/OPEN_EXCEEDED_LIMIT_DIALOG";
const CLOSE_EXCEEDED_LIMIT_DIALOG = "auth/CLOSE_EXCEEDED_LIMIT_DIALOG";
const CLEAR_ALL_ORDERS = "auth/CLEAR_ALL_ORDERS";

const RATE_AN_ORDER_REQ = "auth/RATE_AN_ORDER_REQ";
const RATE_AN_ORDER_SCS = "auth/RATE_AN_ORDER_SCS";
const RATE_AN_ORDER_FLR = "auth/RATE_AN_ORDER_FLR";

const VALIDATION_MESSAGE = "auth/VALIDATION_MESSAGE";
const VALIDATION_CLEAR = "auth/VALIDATION_CLEAR";

const CONTROL_VARIABLE_FOR_RATE_ORDER = "auth/CONTROL_VARIABLE_FOR_RATE_ORDER";

const CANCEL_AN_ORDER_REQ = "auth/CANCEL_AN_ORDER_REQ";
const CANCEL_AN_ORDER_SCS = "auth/CANCEL_AN_ORDER_SCS";
const CANCEL_AN_ORDER_FLR = "auth/CANCEL_AN_ORDER_FLR";

const GET_AN_ORDER_ITEM_DATA = "auth/GET_AN_ORDER_ITEM_DATA";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/

export const roomBill = (body, func = () => {}) => async (dispatch) => {
	dispatch({ type: ROOM_BILL_REQ });
	func();
	const response = await postFunc(`orders/roomBill`, body);
	if (response.status.errorCode === 200) {
		dispatch({ type: ROOM_BILL_SCS, payload: response.data });
		dispatch({ type: CLEAR_CART });
	} else if (response.status.errorCode === 50) {
		dispatch({
			type: OPEN_EXCEEDED_LIMIT_DIALOG,
			payload: response?.status?.description,
		});
	} else if (response.status.errorCode === 400) {
		dispatch({
			type: ROOM_BILL_FLR,
			payload: response?.status?.description,
		});
		NotificationManager.error(i18n.t("Cart contains invalid items"));
	} else {
		dispatch({ type: ROOM_BILL_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const getAllOrders = (lang) => async (dispatch) => {
	dispatch({ type: GET_ALL_ORDERS_REQ });
	const response = await getFunc(`orders/getAllForGuest/${lang}`);
	if (response.status.errorCode === 200) {
		dispatch({
			type: GET_ALL_ORDERS_SCS,
			payload: response.data,
		});
	} else {
		dispatch({ type: GET_ALL_ORDERS_FLR });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const closeCheckoutDialog = () => async (dispatch) => {
	dispatch({ type: CLOSE_CHECKOUT_DIALOG });
};

export const clearErrors = () => async (dispatch) => {
	dispatch({ type: CLEAR_ERRORS });
};

export const closeExceededLimitDialog = () => async (dispatch) => {
	dispatch({ type: CLOSE_EXCEEDED_LIMIT_DIALOG });
};

export const clearAllOrders = () => async (dispatch) => {
	dispatch({ type: CLEAR_ALL_ORDERS });
};

export const rateAnOrder = (orderId, id, body) => async (dispatch) => {
	dispatch({ type: RATE_AN_ORDER_REQ });
	const response = await putFunc(`orderItems/review/${id}`, body);
	if (response.status.errorCode === 200) {
		dispatch({
			type: RATE_AN_ORDER_SCS,
			payload: response.data,
			orderId,
		});
		dispatch({ type: VALIDATION_CLEAR });
		NotificationManager.success(i18n.t(response.status.description));
	} else if (response.status.errorCode === 400) {
		dispatch({ type: RATE_AN_ORDER_FLR });
		dispatch({
			type: VALIDATION_MESSAGE,
			message: response.status.description,
		});
	} else {
		dispatch({ type: RATE_AN_ORDER_FLR, error: response.status.description });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const cancelAnOrder = (id) => async (dispatch) => {
	dispatch({ type: CANCEL_AN_ORDER_REQ });
	const response = await putFunc(`orderItems/cancelByGuest/${id}`);
	if (response.status.errorCode === 200) {
		dispatch({
			type: CANCEL_AN_ORDER_SCS,
			payload: response.data,
		});
		dispatch({ type: VALIDATION_CLEAR });
		NotificationManager.success(i18n.t(response.status.description));
	} else if (response.status.errorCode === 400) {
		dispatch({ type: CANCEL_AN_ORDER_FLR });
		dispatch({
			type: VALIDATION_MESSAGE,
			message: response.status.description,
		});
	} else {
		dispatch({ type: CANCEL_AN_ORDER_FLR, error: response.status.description });
		NotificationManager.error(i18n.t(response.status.description));
	}
};

export const controlVariableForRateAnOrder = () => async (dispatch) => {
	dispatch({ type: CONTROL_VARIABLE_FOR_RATE_ORDER });
};

export const getAnOrderItemData = (id, orderID) => async (dispatch) => {
	dispatch({ type: GET_AN_ORDER_ITEM_DATA, id, orderID });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/

const INIT_STATE = {
	successfullyPaid: false,
	closeAfterError: false,
	orders: {
		loading: false,
	},
	errors: null,
	exceededDialog: false,
	exceededMsg: null,
	successfullyRated: false,
	orderItemData: null,
};

export default function reducer(state = INIT_STATE, action = {}) {
	switch (action.type) {
		case ROOM_BILL_REQ:
			return {
				...state,
				successfullyPaid: false,
				errors: null,
				orders: {
					...state.orders,
					loading: true,
				},
			};
		case ROOM_BILL_SCS:
			const orderItemsTemp = action.payload?.order_items?.map((i) => {
				return {
					category: i.category,
					extras: i.extras,
					gallery: i.gallery,
					order_item: i.order_item,
					organisation: i.organisation,
					service: i.service,
					total: i.total,
					organisation_working_hours_sub: i.organisation_working_hours,
				};
			});

			state.orders?.data?.unshift({
				...action.payload,
				order_items: orderItemsTemp,
			});

			return {
				...state,
				successfullyPaid: true,
				errors: null,
				orders: {
					loading: false,
					data: state.orders?.data,
				},
			};
		case ROOM_BILL_FLR:
			return {
				...state,
				successfullyPaid: false,
				closeAfterError: true,
				errors: action.payload,
				orders: {
					loading: false,
					//...state.orders,
				},
			};
		case OPEN_EXCEEDED_LIMIT_DIALOG:
			return {
				...state,
				successfullyPaid: false,
				closeAfterError: true,
				errors: null,
				exceededDialog: true,
				exceededMsg: action.payload,
				orders: {
					loading: false,
					...state.orders,
				},
			};
		case CLEAR_ERRORS:
			return {
				...state,
				errors: null,
			};
		case GET_ALL_ORDERS_REQ:
			return {
				...state,
				orders: {
					loading: true,
				},
			};
		case GET_ALL_ORDERS_SCS:
			return {
				...state,
				orders: {
					loading: false,
					data: action.payload.map((i) => {
						return {
							...i,
							order_items: i.order_items.sort((a, b) => {
								if (a.category?.template < b.category?.template) {
									return -1;
								}
								if (a.category?.template > b.category?.template) {
									return 1;
								}
								return 0;
							}),
							total: i.order_items.reduce((a, b) => +a + +b.total, 0),
						};
					}),
				},
			};
		case GET_AN_ORDER_ITEM_DATA:
			const temp = state.orders?.data?.find((i) => i.id === action.orderID);
			return {
				...state,
				orderItemData: temp?.order_items?.find(
					(j) => j.order_item?.id === action.id
				),
			};
		case GET_ALL_ORDERS_FLR:
			return {
				...state,
				orders: {
					loading: false,
					error: action.error,
				},
			};
		case CLOSE_CHECKOUT_DIALOG:
			return {
				...state,
				successfullyPaid: false,
				closeAfterError: false,
			};
		case CLOSE_EXCEEDED_LIMIT_DIALOG:
			return {
				...state,
				exceededDialog: false,
				exceededMsg: null,
			};
		case CLEAR_ALL_ORDERS:
			return {
				...state,
				orders: {
					loading: false,
				},
			};
		case RATE_AN_ORDER_SCS:
			const order1 = state.orders?.data?.map((i) => {
				if (i.id === action.payload.order_id) {
					const order_items_temp1 = i.order_items.map((j) => {
						if (j.order_item?.id === action.payload.id) {
							const order_item_temp1 = {
								...j.order_item,
								review_point: action.payload.review_point,
								review_description: action.payload.review_description,
							};
							return { ...j, order_item: order_item_temp1 };
						} else return j;
					});
					return { ...i, order_items: order_items_temp1 };
				}
				return i;
			});
			return {
				...state,
				successfullyRated: true,
				orders: {
					loading: false,
					data: order1,
				},
			};
		case CONTROL_VARIABLE_FOR_RATE_ORDER:
			return {
				...state,
				successfullyRated: false,
			};

		case CANCEL_AN_ORDER_REQ:
			return {
				...state,
				orders: {
					loading: true,
					...state.orders,
				},
			};
		case CANCEL_AN_ORDER_SCS:
			const order = state.orders?.data?.map((i) => {
				if (i.id === action.payload.order_id) {
					const order_items_temp = i.order_items.map((j) => {
						if (j.order_item?.id === action.payload.id) {
							const order_item_temp = {
								...j.order_item,
								state: action.payload.state,
							};
							return { ...j, order_item: order_item_temp };
						} else return j;
					});
					return { ...i, order_items: order_items_temp };
				}
				return i;
			});
			return {
				...state,
				orders: {
					loading: false,
					data: order,
				},
			};
		case CANCEL_AN_ORDER_FLR:
			return {
				...state,
				orders: {
					loading: false,
					...state.orders,
					error: action.error,
				},
			};
		default:
			return state;
	}
}
