// React
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// MUI
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
	horizontal_line_dialog: {
		height: "2px",
		marginLeft: "0",
		backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
		borderWidth: "0px",
		marginTop: "auto",
	},
}));

const TitleWithDivider = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { title, size, customStyle, width, onClick, dataCy } = props;

	return (
		<>
			<Box style={customStyle} onClick={onClick} dataCy={dataCy}>
				<Typography style={{ fontSize: size }}>
					{t(title).toUpperCase()}
				</Typography>
				<hr className={classes.horizontal_line_dialog} width={width} />
			</Box>
		</>
	);
};

TitleWithDivider.defaultProps = {
	title: "",
	size: "18px",
	customStyle: {},
	width: "80px",
	onClick: null,
	dataCy: null,
};

export default TitleWithDivider;
