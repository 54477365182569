// React, Redux
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Popover } from "@material-ui/core";

// Atoms
import Button from "Components/atoms/buttons/Button";

// Molecules
import ListItem from "Components/molecules/ListItem";

// Utils
import moment from "moment";
import { getCookie, getCookieWithPromise } from "Util/functions";

const ChangeLanguage = (props) => {
	const { dataCy, getData, header } = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const { i18n } = useTranslation();
	const langs = JSON.parse(localStorage.getItem("theme"))?.Languages;

	useEffect(() => {
		getCookieWithPromise("lang").then((response) => {
			i18n.changeLanguage(response);
			moment.locale(response);
		});
		return () => {};
	}, []);

	const changeLanguage = (value) => {
		console.log(value);
		i18n.changeLanguage(value);
		moment.locale(value);
		document.cookie = `lang=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
		getData ? getData(value) : window.location.reload();
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<>
			<Button
				customFontColor={true}
				onClick={handleClick}
				label={getCookie("lang")}
				dataCy={dataCy}
				customStyle={{
					justifyContent: header ? "flex-end" : "initial",
					padding: header ? "6px 8px" : "initial",
				}}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				{langs?.map((item) => (
					<ListItem
						key={item.id}
						primaryLabel={`${item.description} (${item.text})`}
						selected={getCookie("lang") === item.text}
						clickable
						onClick={() => changeLanguage(item.text)}
					/>
				))}
			</Popover>
		</>
	);
};

ChangeLanguage.defaultProps = {
	dataCy: null,
	getData: null,
};

export default ChangeLanguage;
