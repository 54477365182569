/* eslint-disable camelcase */
// React
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material-UI
import {
	Container,
	Box,
	MuiThemeProvider,
	createTheme,
} from "@material-ui/core";

// Layout components
import Footer from "Layouts/sections/Footer";
import SingleViewHeader from "./SingleViewHeader";

// Redux actions
import { getTheme } from "Modules/units/App";

// Loading spinner
import FullScreenSpinner from "Layouts/loaders/FullScreenSpinner";

const TripleSearchCTALayout = (state) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);

	useEffect(() => {
		if (app.theme === null) dispatch(getTheme());
		return () => {};
	}, []);

	const isIos = () => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	};

	return (
		<>
			{app.theme?.mui_theme ? (
				<MuiThemeProvider theme={createTheme(app.theme.mui_theme)}>
					<SingleViewHeader />
					<Container fixed maxWidth="md">
						<Box mt={13} mb={20}>
							{state.children}
						</Box>
					</Container>
					<Box
						style={{
							width: "100%",
							position: "fixed",
							bottom: 0,
							boxShadow: "0px -7px 8px -7px rgba(0,0,0,0.20)",
							paddingBottom: isIos() ? "12px" : null,
							backgroundColor: "white",
							opacity: "0.95",
						}}
					>
						<Container fixed maxWidth="md">
							<Footer />
						</Container>
					</Box>
				</MuiThemeProvider>
			) : (
				<FullScreenSpinner />
			)}
		</>
	);
};

export default TripleSearchCTALayout;
