// React, Redux, Router
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ChatContext } from "Router/MainRouter";

// MUI
import Badge from "@material-ui/core/Badge";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ChatIcon from "@material-ui/icons/Chat";
import PersonIcon from "@material-ui/icons/Person";
import { makeStyles } from "@material-ui/core/styles";

// Sounds
import msgSound from "Assets/sounds/msg_sound.mp3";

// Actions
import { getChatRooms, updateChatRooms } from "Modules/units/Chat";

const messageNotificationSound = new Audio(msgSound);

const style = makeStyles(() => ({
	stickToBottom: {
		width: "100%",
		position: "fixed",
		bottom: 0,
	},
	menuItem: {
		minWidth: "70px",
	},
}));

const Footer = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = style();
	const [value, setValue] = useState(window.location.pathname);
	const [unreadNumber, setUnreadNumber] = useState(0);
	const [socketListener, setSocketListener] = useState("");
	let { id, camp_id } = useParams();
	const rooms = useSelector((state) => state.chat.rooms);
	const loggedChatUser = useSelector((state) => state.auth.data.chat_user_id);
	const loggedChatToken = useSelector(
		(state) => state.auth.data.chat_auth_token
	);

	const realTimeAPI = useContext(ChatContext);

	useEffect(() => {
		if (window.location.pathname !== "/chat" && loggedChatToken) {
			dispatch(getChatRooms(realTimeAPI, () => null));
		}
		if (realTimeAPI?.webSocket?.closed === false) {
			realTimeAPI.onMessage((message) => setSocketListener(message));
		}
	}, []);

	useEffect(() => {
		if (rooms) {
			setUnreadNumber(rooms.some((room) => room.unread > 0) ? 1 : 0);
		}
	}, [rooms]);

	useEffect(() => {
		if (
			window.location.pathname !== "/chat" &&
			socketListener?.fields?.eventName === `${loggedChatUser}/notification`
		) {
			setUnreadNumber(1);
			messageNotificationSound.play();
		}
	}, [socketListener]);

	useEffect(() => {
		if (
			window.location.pathname === `/singleview/${id}/${camp_id}` ||
			window.location.pathname === `/selectview/${id}` ||
			window.location.pathname === `/selectviewcollapse/${id}`
		) {
			setValue("/browse");
		} else if (window.location.pathname === `/rating/${id}`) {
			setValue("/profile");
		} else {
			setValue(window.location.pathname);
		}
	}, [value]);

	return (
		<BottomNavigation
			value={value}
			showLabels
			dataCy="bottomNavigation"
			style={{ maxHeight: "56px" }}
		>
			<BottomNavigationAction
				className={classes.menuItem}
				label={t("GLOBAL.PAGE_HOME").toUpperCase()}
				icon={<HomeIcon />}
				component={Link}
				to="/home"
				value="/home"
				dataCy="homeBottomNavigationAction"
			/>
			<BottomNavigationAction
				className={classes.menuItem}
				label={t("GLOBAL.PAGE_BROWSE").toUpperCase()}
				icon={<FastfoodIcon />}
				component={Link}
				to="/browse"
				value="/browse"
				dataCy="browseBottomNavigationAction"
			/>
			{/* <BottomNavigationAction
				className={classes.menuItem}
				label={t("GLOBAL.PAGE_ORDERS").toUpperCase()}
				icon={<PlaylistAddCheckIcon />}
				component={Link}
				to="/orders"
				value="/orders"
				dataCy="ordersBottomNavigationAction"
			/>
			<BottomNavigationAction
				className={classes.menuItem}
				label={t("GLOBAL.PAGE_CHAT").toUpperCase()}
				icon={
					<Badge
						invisible={window.location.pathname === "/chat" || !unreadNumber}
						variant="dot"
						badgeContent={unreadNumber}
						color="error"
					>
						<ChatIcon />
					</Badge>
				}
				component={Link}
				to="/chat"
				value="/chat"
				dataCy="chatBottomNavigationAction"
			/>
			<BottomNavigationAction
				className={classes.menuItem}
				label={t("GLOBAL.PAGE_PROFILE").toUpperCase()}
				icon={<PersonIcon />}
				component={Link}
				to="/profile"
				value="/profile"
				dataCy="profileBottomNavigationAction"
			/> */}
		</BottomNavigation>
	);
};
export default Footer;
