module.exports = {
	// GLOBAL
	"GLOBAL.NOT_FOUND": "Nicht gefunden",
	"GLOBAL.INVALID_CODE_LABEL": "Ungültiger Code!",
	"GLOBAL.FIRST_NAME_LABEL": "Vorname",
	"GLOBAL.LAST_NAME_LABEL": "Nachname",
	"GLOBAL.ROOM_NUMBER_LABEL": "Zimmernummer",
	"GLOBAL.CLOSE_ACTION": "schließen",
	"GLOBAL.CONTINUE_ACTION": "fortsetzen",
	"GLOBAL.CONFIRM_ACTION": "bestätigen",
	"GLOBAL.PROCEED_ACTION": "Vorgehen",
	"GLOBAL.SUBMIT_ACTION": "einreichen",
	"GLOBAL.NEXT_ACTION": "nächste",
	"GLOBAL.BACK_ACTION": "zurücksetzen",
	"GLOBAL.SKIP_ACTION": "überspringen",
	"GLOBAL.SEARCH": "Suche...",
	"GLOBAL.TOTAL_LABEL": "gesamt",
	"GLOBAL.PAGE_HOME": "Startseite",
	"GLOBAL.PAGE_BROWSE": "Suche",
	"GLOBAL.PAGE_ORDERS": "Aufträge",
	"GLOBAL.PAGE_CHAT": "chat",
	"GLOBAL.PAGE_PROFILE": "Profil",
	"GLOBAL.CHOOSE_LANG_TITLE": "Willkommen zu",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_1":
		"Für ein optimales Erlebnis wählen Sie bitte Ihre bevorzugte Sprache aus",
	"GLOBAL.CHOOSE_LANG_SUBTITLE_2": "Du kannst es später immer noch ändern",
	"GLOBAL.SWW": "Etwas ist schief gelaufen!",
	"GLOBAL.SWW_OPTION_1": "Aktualisieren",
	"GLOBAL.SWW_OPTION_2": "Zur Homepage gehen",
	"GLOBAL.LANGUAGE": "Sprache",
	"GLOBAL.SERVICES": "Dienste",
	"GLOBAL.ORDERS": "Bestellungen",
	"GLOBAL.APPOINTMENTS": "Termine",
	"GLOBAL.EVENTS": "Ereignisse",
	"GLOBAL.ANDERE": "ANDERE",
	"GLOBAL.QUANTITY": "Menge",
	"GLOBAL.SERVICE": "Dienst",
	"GLOBAL.PROCESSING_PAYMENT": "Zahlung wird verarbeitet...",

	// STATUSES
	"GLOBAL.STATUS_PROCESSING_LABEL": "Verarbeitung",
	"GLOBAL.STATUS_UNSUCESSFULLY_PROCESSED_LABEL":
		"Nicht erfolgreich verarbeitet",
	"GLOBAL.STATUS_FINISHED_LABEL": "Fertig",
	"GLOBAL.STATUS_PENDING_LABEL": "Ausstehend",
	"GLOBAL.STATUS_CANCELED_LABEL": "Otkazano",
	"GLOBAL.STATUS_IN_PROGRESS_LABEL": "In Bearbeitung",

	// SEARCH
	"SEARCH.INPUT_LABEL": "Suche",
	"SEARCH.NO_RESULTS": "Keine Ergebnisse",
	"SEARCH.LOADING": "Ergebnisse werden geladen",
	"SEARCH.MORE_CHARS_LABEL": "Geben Sie {{chars}} weitere Zeichen ein",

	// NOTIFICATIONS
	"NOTIFICATIONS.TITLE": "Benachrichtigungen",
	"NOTIFICATIONS.READ_ALL": "alles lesen",

	// PROFILE
	"PROFILE.LOG_OUT": "Abmelden",
	"PROFILE.NO_ROOM_LABEL": "Gast ist keinem Zimmer zugewiesen",
	"PROFILE.CHECKOUT_LABEL": "Auschecken",
	"PROFILE.SUBSCRIPTIONS": "Meine Abonnements",
	"PROFILE.SUBSCRIPTIONS_USER_INFO_LABEL": "Vor- und Nachname (Raumnummer):",
	"PROFILE.SUBSCRIPTIONS_DURATION_LABEL": "Dauer",
	"PROFILE.NO_SUBSCRIPTIONS":
		"Es gibt keine Abonnements! Bitte überprüfen Sie die Rezeption, um eins zu bekommen!",
	"PROFILE.BALANCE": "Mein Guthaben",
	"PROFILE.MY_SPENDINGS": "Meine Ausgaben",
	"PROFILE.SPENDINGS": "Ausgaben",
	"PROFILE.SPENT": "Ausgegeben",
	"PROFILE.MY_STAY": "Mein Aufenthalt",
	"PROFILE.RATE_STAY": "Bewerten Sie Ihren Aufenthalt",
	"PROFILE.BAN": "BAN",
	"PROFILE.LIMITATION": "Einschränkung",
	"PROFILE.SET_LIMIT": "Limit festlegen",
	"PROFILE.ENTER_AMOUNT": "Geben Sie den Betrag ein",
	"PROFILE.HOW_TO_USE_APP": "Wie verwende ich die App?",
	"PROFILE.TOTAL_ORDERS": "BESTELLUNGEN",
	"PROFILE.ROOM_NUMBER": "Zimmernummer",
	"PROFILE.NO_RESTRICTIONS": "Keine Einschränkungen",
	"PROFILE.SET": "Einstellen",
	"PROFILE.RATE_ACCOMODATION": "Unterkunft",
	"PROFILE.RATE_SERVICE": "Dienst",
	"PROFILE.RATE_REVIEW_TEXT": "Eine Bewertung",
	"PROFILE.RATE_TEXT_PLACEHOLDER": "Hier eingeben...",
	"PROFILE.RATE_DELETE": "Bewertung löschen",

	// ORDERS
	"ORDERS.TITLE": "meine Bestellungen & Termine",
	"ORDERS.EMPTY_TXT":
		"Es gibt keine Bestellungen! Machen Sie eine Bestellung und sie erscheint hier!",
	"ORDERS.CANCEL_ACTION": "stornieren",
	"ORDERS.ITEM_SHOW_DETAILS": "Details anzeigen",
	"ORDERS.ITEM_FREE_LABEL": "Kostenlos",
	"ORDERS.ITEM_RESERVED_LABEL": "RESERVIERT",
	"ORDERS.RATE_ACTION": "Preis",
	"ORDERS.RATE_SERVICE_LABEL": "Dienst",
	"ORDERS.RATE_REVIEW_LABEL": "Eine Rezension",
	"ORDERS.RATE_MSG_PLACEHOLDER": "Hier eingeben...",
	"ORDERS.CANCEL_LABEL": "Bestellung stornieren",
	"ORDERS.CANCEL_TEXT": `Sind Sie sicher, dass Sie {{orderItemQuantity}} X {{orderItemName}} aus der Bestellung entfernen möchten?`,
	"ORDERS.CANCEL_CONFIRM": "Ja, ich bin sicher",
	"ORDERS.ON_ROOM_BILL_LABEL": "auf Zimmerrechnung",
	"ORDERS.TIME_LEFT_FOR_DELIVERY":
		"Noch {{time}} bis Bestellartikel fertig ist",
	"ORDERS.ITEM_PICK_UP_AT_LABEL": "Abholung um",

	// CHAT
	"CHAT.DISABLED": "Chat ist deaktiviert",
	"CHAT.NEW_CONVERSATION": "Neue Konversation hinzufügen",
	"CHAT.REMOVE_CONVERSATION": "Konversation entfernen",
	"CHAT.TYPE_MESSAGE": "Nachricht hier eingeben",

	// BROWSE
	"BROWSE.TEMPLATE_ORDER_ORDER_LABEL": "Menge und Extras wählen",
	"BROWSE.TEMPLATE_EVENT_ORDER_LABEL": "Wählen Sie die Anzahl der Tickets",
	"BROWSE.TEMPLATE_DATE_RANGE_ORDER_LABEL": "reservieren",
	"BROWSE.TEMPLATE_APPOINTMENT_ORDER_LABEL": "Termin vereinbaren",
	"BROWSE.REVIEWS_LABEL": "Bewertungen",
	"BROWSE.TEMPLATE_DATE_RANGE_SUBMIT_ALREADY_IN_CART_MSG":
		"Dieser Artikel ist bereits im Warenkorb!",
	"BROWSE.TEMPLATE_APPOINTMENT_PICK_MSG": "Bitte wählen Sie einen Termin aus!",
	"BROWSE.ITEM_NOT_RATED": "nicht bewertet",
	"BROWSE.ITEM_MAX_NUM_OF_RESERVATIONS":
		"Max. Anzahl der einmaligen Reservierungen ist ",
	"BROWSE.ADDED_TO_CART_MSG": "Warnkorb erfolgreich hinzugefügt!",
	"BROWSE.ITEM_NO_PRICE_OR_READONLY_OR_DISABLED_ORDERING_MSG":
		"Terminvereinbarung ist derzeit nicht möglich. Bitte versuchen Sie es später noch einmal!",

	// CART
	"CART.TITLE": "Mein Warenkorb",
	"CART.EMPTY_TITLE": "Ihr Warenkorb ist leer!",
	"CART.EMPTY_SUBTITLE":
		"Artikel durchsuchen, um sie in den Einkaufswagen zu legen",
	"CART.SCHEDULE_DELIVERY": "Lieferung planen",
	"CART.SCHEDULED_TIME_GREATER_THAN_CURRENT_TIME_MSG":
		"Geplante Zeit muss größer als aktuelle Zeit sein.",
	"CART.ORGANISATION_WORKING_TIME_FROM_MSG":
		"Die Arbeitszeit dieser Organisation ist von",
	"CART.ORGANISATION_WORKING_TIME_TO_MSG": "an",
	"CART.SCHEDULED_TIME_HAS_TO_BE_AFTER_MSG": "Lieferzeit muss später sein",
	"CART.CHECKOUT_ACTION": "Zur Kasse",
	"CART.REMOVE_ALL_ACTION": "Alle entfernen",
	"CART.TOTAL_LABEL": "GESAMTE ARTIKEL",
	"CART.ITEM_SHOW_DETAILS": "Details anzeigen",
	"CART.ITEM_FREE_LABEL": "Kostenlos",
	"CART.ITEM_ROOM_SERVICE_LABEL": "Zimmerservice",
	"CART.ITEM_PICK_UP_AT_LABEL": "Abholung um",
	"CART.ROOM_BILL_ACTION": "Zimmerrechnung",
	"CART.PAY_WITH_CARD_ACTION": "mit Karte bezahlen",
	"CART.ORDER_SCS_TITLE": "Bestellung erfolgreich aufgegeben!",
	"CART.ORDER_SCS_SUBTITLE":
		"Sie können Ihre Bestellungen jederzeit im Abschnitt BESTELLUNGEN im Hauptmenü verfolgen.",
	"CART.ORDER_MORE_ACTION": "mehr bestellen",
	"CART.ORDER_MY_ORDERS_ACTION": "Meine Bestellungen",
	"CART.ORDER_EXCEEDED_LIMIT_MSG": "Sie haben das Limit überschritten!",
	"CART.ORDER_CANCEL_OR_CONTINUE":
		"Möchten Sie eine Aktion abbrechen oder fortfahren?",
	"CART.ORDER_DATE_TO_FROM_VALIDATION_MSG":
		"Datum bis muss größer als Datum von sein",
	"CART.ORDER_INVALID_ITEMS_MSG": "Warenkorb enthält ungültige Artikel",
	"CART.ORDER_CARD_PAYMENT_MSG": "Kartenzahlung ist noch nicht implementiert!",
	//"CART.ORDER_SCHEDULED_TIME_PAST_MSG":"Scheduled date is in past",

	// RATE DIALOG
	"RATE_DIALOG.TITLE": "Bewerten Sie unsere App",
	"RATE_DIALOG.RATE_TEXT":
		"Ihre Bewertung wird uns helfen, GuestApp zu verbessern. Bitte sagen Sie uns Ihre ehrliche Meinung!",
	"RATE_DIALOG.RATE_ACTION": "Bewertung",
	"RATE_DIALOG.CANCEL_ACTION": "nicht jetzt",
	"RATE_DIALOG.NO_STARS_MSG": "Bitte wählen Sie Sterne von 1 bis 5 aus!",

	// ORDER
	"ORDER.CHOOSE_QUANTITY": "Menge auswählen",
	"ORDER.ADD_TO_CART_ACTION": "Zum Warenkorb",
	"ORDER.TEMPLATE_ORDER_EXTRAS": "Extras",
	"ORDER.TEMPLATE_ORDER_DELIVERY_TYPE": "Lieferart",
	"ORDER.TEMPLATE_ORDER_ITEM_ROOM_SERVICE_LABEL": "Zimmerservice",
	"ORDER.TEMPLATE_ORDER_ITEM_PICK_UP_AT_LABEL": "Abholung um",
	"ORDER.TEMPLATE_DATE_RANGE_STEP_1_TITLE":
		"Wählen Sie einen oder mehrere Tage aus",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_1": "Datum und Menge auswählen",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_1_TITLE_2": "Datum wählen",
	"ORDER.TEMPLATE_APPOINTMENT_STEP_2_TITLE": "Genaue Uhrzeit auswählen",
	"ORDER.TEMPLATE_APPOINTMENT_NO_MAX_CAPACITY_TXT":
		"Terminvereinbarung ist derzeit nicht möglich. Bitte versuchen Sie es später noch einmal!",
	"ORDER.TEMPLATE_DATE_RANGE_FROM_LABEL": "Von",
	"ORDER.TEMPLATE_DATE_RANGE_TO_LABEL": "Bis",

	// TUTORIAL
	"TUTORIAL.SKIP": "Tutorial überspringen",

	// VALIDATION
	"VALIDATION.APPLICATION_REVIEW_SCS": "Your rating is successfully saved",
	//"VALIDATION.STAR_RATING_REQUIRED": "Star rating is required",
	"VALIDATION.STAY_REVIEW_SCS": "Your review for stay is successfully saved!",
	"VALIDATION.STAY_REVIEW_DELETED_SCS":
		"Your review for stay is successfully deleted!",
	"VALIDATION.LIMIT_UPDATED_SCS": "Limit is successfully updated!",
	"VALIDATION.ORDER_ITEM_CANCEL_FLR": "You cannot cancel an order item",
	"VALIDATION.ORDER_ITEM_CANCEL_SCS": "Item is successfully canceled!",
	"VALIDATION.CALENDAR_AVAILABLE_TERMINS_FLR":
		"The calendar has no dates available",

	// OTHERS
	Popular: "Populär",
	"special offer": "Sonderangebot",
	"Special offer": "Sonderangebot",
	Ban: "Verbot",
	Limitation: "Einschränkung",
};
