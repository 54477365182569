// React
import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Utils
import { getCookie } from "Util/functions";
import { GLOBAL_VARIABLES } from "Util/idConfig";

// Swiper
import { useSwipeable } from "react-swipeable";

// Icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// MUI
import { Dialog, Slide, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

// Atoms
import AlternativeAutocomplete from "Components/atoms/inputs/AlternativeAutocomplete";
import IconButton from "Components/atoms/buttons/IconButton";
import Button from "Components/atoms/buttons/Button";

// Molecules
import TitleWithDivider from "Components/molecules/TitleWithDivider";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const SearchBar = () => {
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [service, setService] = useState("");
	const auth = useSelector((state) => state.auth);
	const [windowResizing, setWindowResizing] = useState(false);
	const [windowHeight, setWindowHeight] = useState(0);
	const orgID = useSelector(
		(state) => state.app?.theme?.organisation?.org_core_id
	);
	const { t } = useTranslation();

	useEffect(() => {
		let timeout;
		const handleResize = () => {
			clearTimeout(timeout);

			setWindowResizing(true);
			setWindowHeight(window.visualViewport.height);

			timeout = setTimeout(() => {
				setWindowResizing(false);
			}, 200);
		};
		if (open) {
			window.visualViewport.addEventListener("resize", handleResize);
		} else {
			window.visualViewport.removeEventListener("resize", handleResize);
		}

		return () => {};
	}, [open]);

	const handleItem = (event) => {
		localStorage.setItem(
			"forStatistics",
			JSON.stringify(GLOBAL_VARIABLES.forStatistics.search)
		);
		setService(event);
		history.push("/singleview/" + event?.id + "/" + null);
	};

	const handlers = useSwipeable({
		onSwipedUp: ({ event }) => {
			if (event.target.id === "searchDialog") {
				handleClose();
			}
		},
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			<IconButton icon={<SearchIcon />} onClick={handleClickOpen} />

			<Dialog
				{...handlers}
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				PaperProps={{ id: "searchDialog" }}
			>
				<Box
					style={{ padding: "20px", paddingTop: "40px", paddingBottom: "0px" }}
				>
					<TitleWithDivider
						title="Search menu"
						customStyle={{ float: "left" }}
					/>
					<Button
						label="close"
						customStyle={{ float: "right" }}
						onClick={handleClose}
						customFontColor
					/>
				</Box>

				<Box
					style={{
						overflow: "auto",
						paddingLeft: "20px",
						paddingRight: "20px",
						paddingTop: "10px",
						paddingBottom: "40px",
					}}
				>
					<AlternativeAutocomplete
						service="service/autocompleteAllForGuest"
						label={"SEARCH.INPUT_LABEL"}
						variant={"standard"}
						autoFocus={true}
						value1={[2]}
						value2={[8]}
						value={service}
						lang={getCookie("lang", auth)}
						listBoxHeight={windowHeight / 2}
						setParentState={(e) => handleItem(e)}
						charsToTrigger={3}
					/>
				</Box>

				<Box
					style={{
						bottom: 0,
						position: "fixed",
						width: "100%",
						backgroundColor: "white",
					}}
				>
					<IconButton
						color="default"
						size="medium"
						customStyle={{ width: "100%" }}
						icon={<ExpandLessIcon />}
						onClick={handleClose}
					/>
				</Box>
			</Dialog>
		</>
	);
};

export default memo(SearchBar);
