// React
import React from "react";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const FullScreenSpinner = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <CircularProgress></CircularProgress>
    </Box>
  );
};

export default FullScreenSpinner;
