// React
import React from "react";

// MUI
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Molecules
import NotificationContent from "Components/molecules/NotificationContent";
import SelectViewContent from "Components/molecules/SelectViewContent";
import ViewAnItemContent from "Components/molecules/ViewAnItemContent";
import WarningCartMessage from "Components/molecules/WarningCartMessage";
import CartContent from "Components/molecules/CartContent";
import TimelineContent from "Components/molecules/TimelineContent";

// Router

//Utils
import { GLOBAL_VARIABLES } from "Util/idConfig";

const useStyles = makeStyles((theme) => ({
	cardroot: {
		display: "flex",
		marginTop: "15px",
		boxShadow: theme.shadows[2],
		position: "relative",
		width: "100%",
		borderRadius: "10px",
	},
	cardrootWithoutHeight: {
		display: "flex",
		marginTop: "15px",
		boxShadow: theme.shadows[2],
		position: "relative",
		width: "100%",
		borderRadius: "10px",
	},
	cardrootUnreadNotification: {
		display: "flex",
		marginTop: "15px",
		boxShadow: theme.shadows[2],
		position: "relative",
		width: "100%",
		borderRadius: "10px",
		backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , #ffffff 100%)`,
	},
	details: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
		width: "-webkit-fill-available",
	},
	cover: {
		minWidth: "35%",
		width: "35%",
		borderRadius: "10px",
	},
	coverWithoutHeight: {
		width: "35%",
		borderRadius: "10px",
	},
	wrapper: {
		padding: "8px",
		minHeight: "120px",
		display: "grid",
		alignContent: "space-between",
	},
	divSquareTopLeft: {
		width: "min-content",
		height: "78px",
		float: "left",
	},
	divSquareTopRight: {
		height: "78px",
		float: "right",
	},
	divSquareBottomLeft: {
		height: "43px",
		float: "left",
	},
	divSquareBottomRight: {
		height: "43px",
		float: "right",
	},
	header: {
		fontWeight: 600,
	},
	description: {
		fontWeight: 400,
		fontSize: "smaller",
	},
	itemSize: {
		backgroundColor: theme.palette.primary.main,
		padding: "1px 8px",
		borderRadius: "10px",
		color: "#fff",
		fontSize: "14px",
		fontWeight: "500",
		borderStyle: "solid",
		borderColor: "white",
		margin: "5px",
	},
	rateLabel: {
		backgroundColor: "white",
		padding: "1px 8px",
		borderRadius: "10px",
		color: theme.palette.primary.main,
		fontSize: "14px",
		fontWeight: "500",
		borderStyle: "solid",
		borderColor: "white",
		margin: "10px",
		width: "100%",
		textAlign: "center",
	},
	itemImage: {
		display: "flex",
		justifyContent: "left",
		alignItems: "flex-end",
		width: "45%",
		borderRadius: "10px",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		zIndex: "0",
	},
	redDot: {
		display: "inline-block",
		marginTop: "20px",
		borderRadius: "50%",
		borderStyle: " solid",
		borderColor: "transparent",
		height: "10px",
		width: "10px",
		background: "red",
		alignSelf: "center",
	},
	label: {
		backgroundColor: "#ffffffeb",
		borderRadius: "4px",
		color: theme.palette.primary.main,
		fontWeight: "500",
		width: "80%",
		height: "30px",
		textAlign: "center",
		padding: "0px 3px",
		margin: "10px",
		width: "100%",
		boxShadow: theme.shadows[2],
		paddingTop: "6px",
	},
	descriptionViewAnItem: {
		display: "flex",
		justifyContent: "space-between",
		display: "flex",
		width: "100%",
		flexDirection: "column",
	},
	opacity: {
		display: "flex",
		height: "125px",
		boxShadow: theme.shadows[2],
		position: "relative",
		opacity: "0.3",
		width: "100%",
	},
	stretch: {
		display: "flex",
		boxShadow: theme.shadows[2],
		position: "relative",
		width: "100%",
	},
	warningMessage: {
		position: "absolute",
		marginLeft: "auto",
		margin: "auto",
		textAlign: "center",
		backgroundColor: "lightgrey",
		borderRadius: "5px",
		boxShadow: theme.shadows[2],
		top: "30%",
		left: "90px",
		right: "30px",
		display: "grid",
		alignItems: "center",
		overflowY: "auto",
	},
	globalWarningMessage: {
		position: "absolute",
		marginLeft: "auto",
		margin: "auto",
		textAlign: "center",
		backgroundColor: "indianred",
		borderRadius: "5px",
		boxShadow: theme.shadows[2],
		top: "20%",
		bottom: "20%",
		left: "50px",
		right: "50px",
		display: "grid",
		alignItems: "center",
		overflowY: "scroll",
	},
	wrapTextStyle: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	horizontal_line_dialog: {
		height: "2px",
		marginLeft: "0",
		backgroundImage: `linear-gradient(90deg,  ${theme.palette.secondary.main} 0% , ${theme.palette.primary.main} 100%)`,
		borderWidth: "0px",
		marginTop: "auto",
	},
	/*alternative cart content*/
	container: {
		width: "100%",
		display: "grid",
		gridTemplateColumns: "90px 0.9fr",
		gridTemplateRows: "auto",
		gap: "0px 8px",
		gridAutoFlow: "row",
		gridTemplateAreas: '"header description"',
	},
	cartItemHeader: {
		display: "grid",
		gridTemplateColumns: "1fr",
		gridTemplateRows: "60px 20px",
		gap: "0px 0px",
		gridAutoFlow: "row",
		gridTemplateAreas: '"img" "organisation"',
		gridArea: "header",
	},
	img: {
		gridArea: "img",
		borderRadius: "10px 10px 0px 0px",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
	organisation: {
		gridArea: "organisation",
	},
	cartItemDescription: {
		display: "grid",
		gridTemplateColumns: "1fr",
		gridTemplateRows: "auto",
		gap: "0px 0px",
		gridAutoFlow: "row",
		gridTemplateAreas: '"title" "info" "actions"',
		gridArea: "description",
	},
	title: {
		gridArea: "title",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	titleText: {
		fontSize: "18px",
		fontWeight: "bold",
		display: "-webkit-box",
		overflow: "hidden",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		//marginRight: "8px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "16px",
			//marginRight: "2px"
		},
	},
	info: {
		gridArea: "info",
	},
	infoExtrasText: {
		fontSize: "small",
		fontWeight: "400",
	},
	actions: {
		gridArea: "actions",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	closeBtn: {
		position: "absolute",
		right: "1px",
		top: "1px",
	},
	orderInfo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	infoDateText: {
		fontSize: "smaller",
		color: "dimgray",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		["@media screen and (max-width: 320px)"]: {
			fontSize: "x-small",
		},
	},
	infoExtrasTitle: {
		fontSize: "small",
		marginLeft: "4px",
		color: "dimgray",
	},
	/*alternative timeline content*/
	containerTimeline: {
		width: "100%",
		display: "grid",
		gridTemplateColumns: "90px 1fr",
		gridTemplateRows: "auto",
		gap: "0px 8px",
		gridAutoFlow: "row",
		gridTemplateAreas: '"header description"',
	},
	cartItemHeaderTimeline: {
		display: "grid",
		gridTemplateColumns: "1fr",
		gridTemplateRows: "60px 20px",
		gap: "1px 0px",
		gridAutoFlow: "row",
		gridTemplateAreas: '"img" "organisation"',
		gridArea: "header",
	},
	imgTimeline: {
		gridArea: "img",
		//width: "45%",
		borderRadius: "10px 10px 0px 0px",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
	organisationTimeline: {
		gridArea: "organisation",
	},
	cartItemDescriptionTimeline: {
		display: "grid",
		gridTemplateColumns: "1fr",
		gridTemplateRows: "auto",
		gap: "1px 0px",
		gridAutoFlow: "row",
		gridTemplateAreas: '"title" "info" "labels"',
		gridArea: "description",
	},
	titleTimeline: {
		gridArea: "title",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		margin: "2px 6px 2px 0",
	},
	titleTextTimeline: {
		fontSize: "18px",
		fontWeight: "bold",
		display: "-webkit-box",
		overflow: "hidden",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px",
		},
	},
	infoTimeline: {
		gridArea: "info",
	},
	infoExtrasTextTimeline: {
		fontSize: "small",
		fontWeight: "400",
	},
	labelsTimeline: {
		gridArea: "labels",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		margin: "0 6px 2px 0",
	},
	orderInfoTimeline: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	infoDateTextTimeline: {
		fontSize: "smaller",
		color: "dimgray",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	infoExtrasTitleTimeline: {
		fontSize: "small",
		marginLeft: "4px",
		color: "dimgray",
	},
}));

const CardDistributor = (props) => {
	const classes = useStyles();

	const {
		image,
		component,
		title,
		price,
		status,
		goTo,
		onClick,
		cart,
		selectView,
		handleRemoveItem,
		extras,
		quantity,
		id,
		notifications,
		descriptionText,
		customStyle,
		timeline,
		accomodation,
		rateOrder,
		setParentState,
		handleLocalStorage,
		unique_id,
		dataCy,
		currency,
		viewAnItem,
		date,
		dateTo,
		template,
		error,
		profile,
		displayDate,
		organisation,
		organisations,
		readOrUnread,
		notification_state,
		orderID,
		serviceID,
		pickupType,
		deliveryOrg,
		deliveryOrgName,
		duration,
	} = props;

	return (
		<>
			<Card
				className={
					notifications
						? notification_state ===
						  GLOBAL_VARIABLES.notification_state.recieved
							? classes.cardrootUnreadNotification
							: classes.cardrootWithoutHeight
						: viewAnItem || profile
						? classes.cardrootWithoutHeight
						: classes.cardroot
				}
				style={{
					height: cart ? "auto" : null,
					minHeight: cart || timeline ? "80px" : "auto",
				}}
				onClick={onClick}
				dataCy={dataCy}
			>
				{cart && (
					<>
						<CartContent
							image={image}
							title={title}
							price={price}
							onClick={onClick}
							extras={extras}
							organisation={organisation}
							organisations={organisations}
							quantity={quantity}
							customStyle={customStyle}
							setParentState={setParentState}
							handleLocalStorage={handleLocalStorage}
							unique_id={unique_id}
							dataCy={dataCy}
							currency={currency}
							classes={classes}
							handleRemoveItem={handleRemoveItem}
							date={date}
							dateTo={dateTo}
							template={template}
							error={error === null ? false : true}
							pickupType={pickupType}
							deliveryOrg={deliveryOrg}
							deliveryOrgName={deliveryOrgName}
							duration={duration}
						/>
						{error && <WarningCartMessage classes={classes} error={error} />}
					</>
				)}

				{notifications && (
					<NotificationContent
						image={image}
						component={component}
						title={title}
						handleRemoveItem={handleRemoveItem}
						descriptionText={descriptionText}
						setParentState={setParentState}
						handleLocalStorage={handleLocalStorage}
						classes={classes}
						readOrUnread={readOrUnread}
					/>
				)}

				{(timeline || profile) && (
					<TimelineContent
						image={image}
						component={component}
						title={title}
						price={price}
						extras={extras}
						quantity={quantity}
						id={id}
						rateOrder={rateOrder}
						setParentState={setParentState}
						handleLocalStorage={handleLocalStorage}
						unique_id={unique_id}
						currency={currency}
						classes={classes}
						displayDate={displayDate}
						organisation={organisation}
						date={date}
						dateTo={dateTo}
						template={template}
						status={status}
						organisations={organisations}
						orderID={orderID}
						serviceID={serviceID}
						pickupType={pickupType}
						deliveryOrg={deliveryOrg}
						deliveryOrgName={deliveryOrgName}
						duration={duration}
						timeline={timeline}
					/>
				)}

				{viewAnItem && (
					<ViewAnItemContent
						title={title}
						price={price}
						quantity={quantity}
						descriptionText={descriptionText}
						accomodation={accomodation}
						setParentState={setParentState}
						handleLocalStorage={handleLocalStorage}
						unique_id={unique_id}
						currency={currency}
						status={status}
						classes={classes}
					/>
				)}

				{selectView && (
					<SelectViewContent
						image={image}
						component={component}
						title={title}
						price={price}
						status={status}
						setParentState={setParentState}
						handleLocalStorage={handleLocalStorage}
						unique_id={unique_id}
						currency={currency}
						classes={classes}
					/>
				)}
			</Card>
		</>
	);
};

CardDistributor.defaultProps = {
	image: "",
	component: "img",
	title: "Title",
	price: "",
	status: false,
	goTo: "",
	selectView: false,
	cart: false,
	notifications: false,
	timeline: false,
	viewAnItem: false,
	handleRemoveItem: () => console.log("remove!"),
	extras: [],
	quantity: 1,
	id: "",
	descriptionText: "",
	customStyle: {},
	accomodation: false,
	rateOrder: false,
	setParentState: null,
	handleLocalStorage: null,
	unique_id: "",
	dataCy: null,
	currency: null,
	date: null,
	dateTo: null,
	template: null,
	error: null,
	profile: false,
	displayDate: null,
	organisation: null,
	organisations: null,
	readOrUnread: false,
	notification_state: null,
	orderID: null,
	serviceID: null,
};

export default CardDistributor;
